<template>
    <section v-show="user != ''" class="p-3" id="admin-panel-section">
        <h1>Pannello di Amministrazione</h1>
        <div class="m-2 row">
            <div class="col-12 text-right">
                <b-button @click="logout" variant="secondary"><i class="fa fa-sign-out-alt"></i> Logout</b-button>
            </div>
        </div>
        <ImmobiliTable v-if="!showInsert" />
        <b-button class="m-2" v-if="!showInsert" @click="insertImmobile" variant="primary"><i class="fa fa-plus"></i>
            Aggiungi Immobile</b-button>
        <InsertImmobile ref="addForm" :formimmobile="form_data" :edit="edit" v-show="showInsert" />
        <b-button v-if="showInsert" @click="resetAndBack" variant="secondary"><i class="fa fa-arrow-left"></i> Gestione
            Immobili</b-button>
        <vue-confirm-dialog></vue-confirm-dialog>
    </section>
</template>
<style>
#admin-panel-section {
    height: 100%;
    margin-top: 150px;
    margin-bottom: 100px;
    font-size: 16px !important;
}

textarea,
input,
.btn {
    font-size: 12px !important;
}
</style>
<script>
import ImmobiliTable from '@/components/ImmobiliTable.vue'
import InsertImmobile from '@/components/InsertImmobile.vue';
import { auth } from "../service/firebaseInit";
import { signOut } from "firebase/auth";
import { mapState } from 'vuex';

export default {
    name: 'AdminPanel',
    components: {
        ImmobiliTable,
        InsertImmobile
    },
    computed: {
        ...mapState(['user'])
    },
    data() {
        return {
            edit: false,
            form_data: {},
            showInsert: false
        }
    },
    mounted() {
        const utente = auth.currentUser;
        if (utente !== null) {
            console.log("Utente: " + utente.email)
            console.log('Login effettuato')
            this.scrollUp();
        } else {
            this.$toast.warning("Non autorizzato", { timeout: 2000 });
            this.$router.push('/')
        }
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        logout() {
            signOut(auth).then(() => {
                this.$toast.info("Logout effettuato con successo", { timeout: 2000 });
                this.$router.push("/")
            }).catch((error) => {
                console.log(error)
            });
        },
        resetAndBack() {
            this.$refs.addForm.clearInputs();
            this.showInsert = false;
            this.scrollUp();
            //this.form_data = {}
        },
        insertImmobile() {
            this.form_data = {};
            this.edit = false;
            this.showInsert = true;
        },
        editImmobile(data) {
            this.form_data = data;
            this.edit = true;
            this.showInsert = true;
        },
        scrollUp() {
            let top = this.$el.querySelector("#admin-panel-section");
            window.scrollTo(0, top);
        },
    }
}   
</script>