<template>
    <cookie-law buttonText="Chiudi" message="www.puntohomeimmobiliare.it utilizza solo cookie tecnici necessari alla navigazione." theme="dark-lime"></cookie-law>
</template>
<style scoped>

</style>
<script>

import CookieLaw from 'vue-cookie-law'

export default {
    name: 'CookieDiv',
    components: { CookieLaw }
}
</script>