<template>
    <div>
        <div class="box">
            <div class="image-container">
                <img class="box-home-mobile" :src="data.foto[0]" alt="">
                <div class="info">
                    <h3 style="background-color: green;" v-if="data.vendita">In Vendita</h3>
                    <h3 style="background-color: blue;" v-if="data.affitto">In Affito</h3>
                </div>
                <div class="stato-div">
                    <h3 style="background-color: red;"> {{ data.stato }}</h3>
                </div>
                <div class="tipologia-div row justify-content-left">
                    <h2 class="tipologia-h2">{{ data.tipologia }}</h2>
                </div>
                <div class="icons">
                    <a :title="'Visualizza ' + data.foto.length + ' foto'" style="cursor: pointer;"
                        @click="showModal(data.foto)"> <i class="fa fa-camera"></i> </a>
                </div>
            </div>
            <div class="content">
                <div class="price">
                    <h3 v-if="isNaN(data.prezzo) || data.prezzo == null">Trattativa in sede</h3>
                    <h3 v-if="!isNaN(data.prezzo)"> {{ data.prezzo | toCurrency }} </h3>
                </div>
                <div class="location d-flex">
                    <i class="fa fa-2x fa-map-marker-alt mr-2" style="color:red"></i>
                    <h2> <b>{{ data.comune }}</b> <span style="font-size: 1.8rem;">- {{ data.zona }} </span></h2>

                </div>
                <div class="details">
                    <h3> <i class="fas fa-expand"></i> <span>MQ {{ data.mq }}</span></h3>
                    <h3> <i class="fas fa-door-closed"></i> <span>{{ data.camere }} Vani</span></h3>
                    <h3> <i class="fas fa-bath"></i> <span>{{ data.bagni }} Bagni</span></h3>
                </div>
                <div class="buttons">
                    <router-link class="btn" :id="data.id" :to="{ path: '/immobile/' + data.id }">
                        Dettagli
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped src="@/assets/css/featured.css">

</style>

<script>
export default {
    name: 'BoxCp',
    props: ['data'],
    methods: {
        showModal(foto) {
            let fotos = []
            foto.forEach(f => {
                fotos.push({
                    src: f,
                    thumbnail: f,
                    w: 900,
                    h: 900,
                    downloadEl: false
                })
            })
            this.$parent.fotoModal = fotos;
            this.$bvModal.show('modal-foto');
        },
    }
}
</script>