<template>
    <section id="arearis-section">
        <h1>Area Riservata</h1>
        <div class="row p-1 mt-3 justify-content-center">
            <div class="col-lg-6 col-md-6 col-sm-12">
                <b-overlay :show="showOv" variant="secondary" spinner-variant="" rounded="sm">
                    <b-card class="mt-3" id="login-card" bg-variant="light">
                        <div class="row justify-content-center">
                            <div class="col-12 text-center">
                                <h2>Accedi al Pannello di Amministrazione</h2>
                            </div>
                            <b-form class="col-6 m-3 p-3" @submit.prevent="accedi" autocomplete="off">
                                <!-- titolo -->
                                <b-form-group id="input-group-username" label-for="input-username" class="mb-2">
                                    <label>Username / Email: </label>
                                    <b-form-input id="input-username" :state="validateState('username')"
                                        v-model="login.username" placeholder="Username"></b-form-input>
                                    <b-form-invalid-feedback id="input-error">Campo Obbligatorio
                                    </b-form-invalid-feedback>
                                </b-form-group>
                                <b-form-group id="input-group-password" label-for="input-password" class="mb-2">
                                    <label>Password: </label>
                                    <b-form-input id="input-password" type="password" :state="validateState('password')"
                                        v-model="login.password" placeholder="Password"></b-form-input>
                                    <b-form-invalid-feedback id="input-error">Campo Obbligatorio
                                    </b-form-invalid-feedback>
                                </b-form-group>
                                <!-- buttons -->
                                <div class="row p-3" style="float:right">
                                    <b-button class="m-1" type="submit" variant="primary"><i class="fa fa-user" />
                                        Accedi</b-button>
                                </div>
                            </b-form>
                        </div>
                    </b-card>
                </b-overlay>
            </div>
        </div>
    </section>
</template>
<style scoped>
section {
    min-height: 100vh;
    height: 100%;
    margin-top: 150px;
    margin-bottom: 100px;
    font-size: 16px !important;
}

.form-group label {
    font-weight: bold !important;
}

.was-validated .form-control:valid,
.form-control.is-valid {
    background-color: #fff !important;
    background-image: unset;
    background-clip: padding-box !important;
    border: 1px solid #ced4da !important;
    border-radius: 0.25rem !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
    color: #495057 !important;
    background-color: #fff !important;
    border-color: #80bdff !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%) !important;
}
</style>
<script>
// @ is an alias to /src
/* eslint-disable */
import { required } from 'vuelidate/lib/validators'
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../service/firebaseInit";

export default {
    name: 'AreaRiservataView',
    components: {
    },
    validations: {
        login: {
            username: {
                required
            },
            password: {
                required
            },
        }
    },
    data() {
        return {
            showOv: false,
            login: {
                username: null,
                password: null
            }
        }
    },
    mounted() {
        const utente = auth.currentUser;
        if(utente !== null) {
            console.log("Utente: " + utente.email)
            this.$router.push("/admin");
        }
        this.scrollUp();
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        accedi() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.$toast.error("Errore nella compilazione del form", {
                    timeout: 2000,
                });
            } else {
                this.showOv = true;
                signInWithEmailAndPassword(auth, this.login.username, this.login.password)
                    .then((userCredential) => {
                        this.$router.push("/admin");
                    })
                    .catch(() => {
                        this.$toast.error("Credenziali non valide!", { timeout: 2000 });
                    }).finally(() => this.showOv = false)
            }
        },
        validateState(name) {
            const { $dirty, $error } = this.$v.login[name];
            return $dirty ? !$error : null;
        },
        scrollUp() {
            let top = this.$el.querySelector("section");
            window.scrollTo(0, top);
        }
    }
}
</script>
  