import { db } from "../service/firebaseInit";
import { getDoc, setDoc, deleteDoc, getDocs, collection, doc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject, } from "firebase/storage";

const storage = getStorage();

export async function getCollection(dataSource) {
    var docs = await getDocs(collection(db, dataSource));
    return docs;
}

export async function getDocumento(dataSource, id) {
    var docRef = doc(db, dataSource, id);
    return getDoc(docRef);
}

export async function saveAllegato(dataSource, image, folder, id) {
    const storageRef = ref(storage, dataSource + "/" + folder + "/" + id);
    var snapshot = await uploadBytes(storageRef, image);
    return snapshot;
}

export async function deleteAllegati(allegati) {
    if (allegati.length > 0) {
        console.log("Eliminazione allegati dallo storage: " + allegati)
        allegati.forEach(a => {
            console.log("- : " + a)
            let x = ref(storage, a);
            deleteObject(x)
        });
    }
    return true
}
export async function getUrlFromRef(snapshot) {
    var url = await getDownloadURL(ref(storage, snapshot.ref));
    return url;
}

export async function saveData(dataSource, form, id) {
    await setDoc(doc(db, dataSource, id), form);
}

export async function deleteData(dataSource, id) {
    await deleteDoc(doc(db, dataSource, id));
}