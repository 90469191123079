<template>
  <div id="app">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css" />
    <Header />
    <GoTop :size="50" title="Torna Su" :right="50" :bottom="50" alt="TOP" fg-color="#fff" bg-color="#ff5e14"
      :radius="5" />
    <router-view />
    <Footer />
    <Cookie/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import GoTop from '@inotom/vue-go-top';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "@/service/firebaseInit";
import { mapState } from 'vuex';
import Cookie from '@/components/Cookie.vue';

export default {
  name: 'PuntoHome',
  components: {
    Header,
    Footer,
    GoTop,
    Cookie
  },
  computed: {
    ...mapState(['user'])
  },
  metaInfo() {
    return {
      meta: [
        {
          name: 'description',
          content: 'PuntoHome di Giuseppe Ortenzio | Agenzia Immobiliare'
        }
      ]
    }
  },

  created() {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.$store.commit("SET_USER", user.email);
      } else {
        this.$store.commit("SET_USER", "");
      }
    });
  }
}
</script>
<style>
@import './assets/css/style.css';
</style>
