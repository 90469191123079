<template>
    <div class="container">
        <section id="immobili-section">
            <div class="row p-1 mt-3">
                <div class="col-sm-12 col-md-12 col-lg-4 col-xl-3" style="justify-content: center; padding: 0">
                    <h1 class="text-center">Cerca il tuo immobile</h1>
                    <hr class="customhr">
                    <div class>
                        <b-form @submit.prevent="onSubmit" @reset.prevent="onReset"
                            style="margin-top:0px !important; background:transparent !important">
                            <div class="row buttons-container" style="margin:20px !important">
                                <div class="col-6">
                                    <b-form-checkbox id="checkbox-vendita" size="lg" :value="true" v-model="p_ric.vendita"
                                        :checked="this.filtro.vendita == true" name="checkbox-vendita"
                                        :unchecked-value="false">
                                        <span class="p-2" style="font-size: 14px"> Vendita</span>
                                    </b-form-checkbox>
                                </div>
                                <div class="col-6">
                                    <b-form-checkbox id="checkbox-affitto" size="lg" :value="true" v-model="p_ric.affitto"
                                        :checked="this.filtro.affitto == true" name="checkbox-affitto"
                                        :unchecked-value="false">
                                        <span class="p-2" style="font-size: 14px"> Affitto</span>
                                    </b-form-checkbox>
                                </div>
                                <!-- <b-form-radio-group id="btn-radio-tipo" size="lg" v-model="tipo_ricerca" :options="tipo_ricerca"  name="radios-btn-tipo"></b-form-radio-group> -->
                            </div>
                            <div class="inputBox mt-2">
                                <!-- <b-form-select v-model="parametri_ricerca.zona" :options="zona"></b-form-select> -->
                                <b-form-select v-model="p_ric.comune" :options="comune"></b-form-select>
                                <b-form-select v-model="p_ric.tipologia" :options="tipologia"></b-form-select>
                                <b-form-select v-model="p_ric.min_prezzo" :options="min_prezzo"></b-form-select>
                                <b-form-select v-model="p_ric.max_prezzo" :options="max_prezzo"></b-form-select>
                                <b-form-input type="number" v-model="p_ric.mq" placeholder="Superficie (MQ)"></b-form-input>
                                <!-- <b-form-select v-model="parametri_ricerca.camere" :options="numero_camere"></b-form-select> -->
                                <b-form-select v-model="p_ric.bagni" :options="numero_bagni"></b-form-select>
                            </div>
                            <b-button class="ricerca-submit" type="submit"><i class="fa fa-search"></i> Cerca</b-button>
                        </b-form>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-8 col-xl-9" style="padding:0">
                    <PropertiesCp :immobili="this.immobili" style="margin-top:10px !important" />
                </div>
            </div>
        </section>
    </div>
</template>
<style scoped>
.featured {
    padding: 0
}

form {
    margin-top: .5rem;
    background: #fff;
    width: auto;
    /* padding: 15px 30px 15px 30px */
}

@media screen and (max-width: 768px) {
    form {
        margin-top: 8rem;
        background: #fff;
        padding: 2rem;
    }
}

@media screen and (max-width: 576px) {
    form {
        margin-top: 5rem;
        background: #fff;
        padding: 2rem;
    }
}

form h3 {
    text-align: center;
    color: #333;
    font-size: 3rem;
}

form .buttons-container {
    text-align: center;
}

form .inputBox {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    margin-top: 2rem;
}

form .inputBox input,
form .inputBox select {
    height: 45px;
    flex: 1 1 25rem;
    border: .1rem solid #333;
    box-shadow: 0 .5rem 1.5rem rgba(0, 0, 0, .1);
    font-size: 1.5rem;
    padding: 1rem;
    text-transform: none;
}

form .btn {
    width: 100% !important;
    margin-top: 2rem !important;
}

.btn {
    margin-top: 1rem !important;
    display: inline-block !important;
    padding: .8rem 3.5rem !important;
    border-radius: .5rem !important;
    font-size: 1.7rem !important;
    color: var(--red) !important;
    background: var(--light-red) !important;
    cursor: pointer !important;
    text-align: center !important;
}

.btn:hover {
    background: var(--red) !important;
    color: #fff !important;
}

.btn {
    border: none
}

section {
    min-height: 100vh;
    height: 100%;
    margin-top: 150px !important;
    margin-bottom: 100px;
    padding: 15px 0 !important
}

@media screen and (max-width: 576px) {
    section {
        margin-top: 100px !important;
        margin-right: auto !important;
    }
}
</style>
<script>
// @ is an alias to /src
// import { getCollection } from '@/service/dataStoreController';
import PropertiesCp from '@/components/Properties.vue';
import { mapState } from 'vuex';
import { getCollection } from '@/service/dataStoreController';
// eslint-disable-next-line
export default {
    name: 'ImmobiliList',
    components: {
        PropertiesCp
    },
    props: ['parametri_ricerca'],
    computed: {
        ...mapState(['immobili_cache', 'filtro'])
    },
    data() {
        return {
            p_ric: {
                vendita: false,
                affitto: false,
                zona: null,
                comune: null,
                tipologia: null,
                min_prezzo: null,
                max_prezzo: null,
                mq: null,
                camere: null,
                bagni: null
            },
            immobili: [],
            tipo_ricerca: [
                { text: 'In Vendita', html: '<label style="font-size:1.5rem"><b>In Vendita</b></label>' },
                { text: 'In Affitto', html: '<label style="font-size:1.5rem"><b>In Affitto</b></label>' },
            ],
            comune: [
                { value: null, text: 'Comune' },
                { value: '', text: 'Tutti i comuni' },
                { value: 'Corato', text: 'Corato' },
                { value: 'Ruvo di Puglia', text: 'Ruvo di Puglia' }
            ],
            zona: [
                { value: null, text: 'Zona' },
                { value: '', text: 'Tutte le zone' },
            ], // caricare da DB
            numero_camere: [
                { value: null, text: 'Camere da letto' },
                { value: 0, text: 'più di 1' },
                { value: 1, text: '1' },
                { value: 2, text: '2' },
                { value: 3, text: '3' },
                { value: 4, text: '4' },
                { value: 5, text: '5' },
                { value: 6, text: '6' }
            ],
            numero_bagni: [
                { value: null, text: 'Bagni' },
                { value: 0, text: 'più di 1' },
                { value: 1, text: '1' },
                { value: 2, text: '2' },
                { value: 3, text: '3' }
            ],
            tipologia: [
                { value: null, text: 'Tipologia' },
                { value: '', text: 'Tutte le tipologie' },
                { value: 'Appartamenti', text: 'Appartamenti' },
                { value: 'Locali e Box', text: 'Locali e Box' },
                { value: 'Capannoni', text: 'Capannoni' },
                { value: 'Attività Commerciali', text: 'Attività Commerciali' },
                { value: 'Ville e Terreni', text: 'Ville e Terreni' }
            ],
            min_prezzo: [
                { value: null, text: 'Prezzo minimo' },
                { value: 0, text: 'Nessun limite' },
                { value: 10000, text: '€ 10.000' },
                { value: 25000, text: '€ 25.000' },
                { value: 50000, text: '€ 50.000' },
                { value: 100000, text: '€ 100.000' },
                { value: 150000, text: '€ 150.000' },
                { value: 200000, text: '€ 200.000' },
                { value: 250000, text: '€ 250.000' },
                { value: 500000, text: '€ 500.000' },
            ],
            max_prezzo: [
                { value: null, text: 'Prezzo massimo' },
                { value: 9999999, text: 'Nessuno limite' },
                { value: 500000, text: '€ 500.000' },
                { value: 250000, text: '€ 250.000' },
                { value: 200000, text: '€ 200.000' },
                { value: 150000, text: '€ 150.000' },
                { value: 100000, text: '€ 100.000' },
                { value: 50000, text: '€ 50.000' },
                { value: 25000, text: '€ 25.000' },
            ]
        }
    },
    mounted() {
        if (this.immobili_cache == null || this.immobili_cache.length == 0) {
            getCollection('immobili').then((docs) => {
                const data = [];
                docs.forEach((doc) => {
                    data.stato
                    data.push(doc.data());
                });
                this.immobili = data
                this.$store.commit('ADDALL_IMMOBILI', data);
            });
            this.ricerca()
        }
        if (this.filtro != null || this.parametri_ricerca != null)
            this.ricerca()

        window.addEventListener('scroll', this.handleScroll);
        this.scrollUp();
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        onSubmit() {
            this.$store.commit("ADD_FILTRO", this.p_ric);
            this.ricerca()
        },
        ricerca() {
            /* Caricamento Immobili */
            this.p_ric = this.parametri_ricerca ? this.parametri_ricerca : this.filtro;
            let p = this.p_ric
            this.immobili = this.immobili_cache.filter(x => x.prezzo >= (p.min_prezzo == null ? 0 : p.min_prezzo) &&
                x.prezzo <= (p.max_prezzo == null ? 9999999 : p.max_prezzo) || isNaN(x.prezzo) || x.prezzo == null)
                .filter(x => p.vendita ? (x.vendita == p.vendita) : (x.vendita != null))
                .filter(x => p.affitto ? (x.affitto == p.affitto) : (x.affitto != null))
                .filter(x => x.tipologia.includes(p.tipologia == null ? "" : p.tipologia))
                .filter(x => x.mq >= (p.mq == null || p.mq == "" ? 0 : parseInt(p.mq)))
                .filter(x => p.zona ? (x.zona.includes(p.zona)) : (x.zona != ""))
                .filter(x => p.comune ? (x.comune.includes(p.comune)) : (x.comune != ""))
                .filter(x => x.bagni >= (p.bagni == null ? 0 : p.bagni))
                .filter(x => x.camere >= (p.camere == null ? 0 : p.camere))
                .sort((a, b) => this.fixData(b.data) - this.fixData(a.data))
        },
        fixData(dataString) {
            let myDate = dataString.split("-");
            let newDate = myDate[2] + "-" + myDate[1] + "-" + myDate[0];
            return Date.parse(newDate)
        },
        scrollUp() {
            let top = this.$el.querySelector("section");
            window.scrollTo(0, top);
        }
    }
}
</script>
