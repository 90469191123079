<template>
    <header class="header" variant="light">
        <div class="container">
            <b-navbar toggleable="md" variant="light">
                <router-link to="/">
                    <b-navbar-brand class="mb-2"><img src="../assets/images/phomelogo.png" id="phome-logo" alt="PuntoHome">
                    </b-navbar-brand>
                </router-link>
                <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
                <b-collapse id="nav-collapse" class="text-right" is-nav right>
                    <b-navbar-nav alig="right">
                        <router-link to="/">
                            Home
                        </router-link>
                        <router-link to="/chisiamo">
                            Chi Siamo
                        </router-link>
                        <router-link to="/servizi">
                            Servizi
                        </router-link>
                        <router-link id="immobili-link" to="/immobili">
                            Immobili
                        </router-link>
                        <router-link to="/contatti">
                            Contatti
                        </router-link>
                        <!-- <router-link id="arearis" to="/areariservata">
                        <b-button title="Area riservata" class="mb-1" variant="secondary"> <i class="fa fa-user"></i>
                        </b-button>
                    </router-link> -->
                    </b-navbar-nav>
                </b-collapse>
            </b-navbar>
        </div>
    </header>
</template>
<script>
export default {
    name: 'HeaderVue'
}
</script>
  
<style scoped src="@/assets/css/header.css"></style>