<template>
    <section class="container">
        <div class="row p-1 mt-3">
            <div class="col-sm-12 col-md-12 col-lg-4 col-xl-3" style="justify-content: center; padding: 0">
                <h1 class="text-center">Contatti</h1>
                <hr class="customhr">
            </div>
        </div>
        <div class="row p-1 mt-3">
            <!-- DOVE SIAMO -->
            <div class="col-lg-12 col-md-12 col-sm-12 col-xl-5">
                <div class="ph-frame">
                    <h2><i class="custom-i fas fa-map-marker-alt"></i> Dove Siamo</h2>
                    <div style="text-align: center; overflow-x: auto;">
                        <iframe class="m-3"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3004.105336409258!2d16.408562815355456!3d41.15405277928625!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13380eec7a1c9a0f%3A0x705338d805bf1774!2sPiazza%20Vittorio%20Emanuele%2C%2016%2C%2070033%20Corato%20BA!5e0!3m2!1sit!2sit!4v1663600232976!5m2!1sit!2sit"
                            width="350px" height="322px"
                            style="border:0;"
                            allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
            <!-- CONTATTI -->
            <div class="col-lg-12 col-md-12 col-sm-12 col-xl-7">
                <div class="ph-frame">
                    <h2> <i class="custom-i fa fa-info-circle"></i> Richiedi Informazioni</h2>
                    <b-overlay :show="showOv" variant="secondary" spinner-variant="" rounded="sm">
                        <b-form ref="form" @submit.prevent="onSubmit" @reset.prevent="onReset">
                            <div class="row">
                                <div class="col-sm-12 col-lg-6">
                                    <b-form-input class="m-2" type="text" name="from_name"
                                        placeholder="Nome e cognome"></b-form-input>
                                </div>
                                <div class="col-sm-12 col-lg-6">
                                    <b-form-select style="font-size: 1.2rem" class="m-2 form-control" name="from_tipo"
                                        value="Informazioni" :options="tipo_richiesta"></b-form-select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-lg-6">
                                    <b-form-input class="m-2" type="email" name="from_email"
                                        placeholder="Email"></b-form-input>
                                </div>
                                <div class="col-sm-12 col-lg-6">
                                    <b-form-input class="m-2" type="tel" name="from_tel"
                                        placeholder="Telefono"></b-form-input>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-lg-6">
                                    <b-form-input class="m-2" type="text" name="from_citta"
                                        placeholder="Città"></b-form-input>
                                </div>
                                <div class="col-sm-12 col-lg-6">
                                    <b-form-input class="m-2" type="tel" name="from_budget"
                                        placeholder="Budget"></b-form-input>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12"> <b-form-textarea id="textarea-default" rows="3" class="m-2"
                                        name="from_message"
                                        placeholder="Descrivi la tua richiesta..."></b-form-textarea>
                                </div>
                            </div>
                            <b-form-checkbox class="m-3" id="checkbox-privacy" size="lg" v-model="privacy"
                                                name="checkbox-privacy" :value="true" :unchecked-value="false"> <span
                                                    class="p-1" style="font-size: 13px">Accetto
                                                    <router-link to="/privacy">
                                                        Privacy
                                                    </router-link></span>
                                            </b-form-checkbox>
                            <vue-recaptcha class="m-2" sitekey="6LdYLdAkAAAAAHjK_NkC6roN0VjrNAPC3-QBDVZ4"></vue-recaptcha>
                            <b-button :disabled="!privacy" class="m-2 btn-back" type="submit"
                                style="border:none; font-size: 16px !important;"><i class="fa fa-paper-plane"></i>
                                Invia</b-button>
                        </b-form>
                    </b-overlay>
                </div>
            </div>
        </div>
    </section>
</template>
<style scoped>
h2 {
    font-weight: bold;
}

.custom-i {
    color: var(--red);
    padding: 5px;
}

section {
    min-height: 100vh;
    height: 100%;
    margin-top: 150px;
    margin-bottom: 100px;
    font-size: 16px !important;
}

@media screen and (max-width: 576px) {
    section {
        margin-top: 100px !important;
        margin-right: auto !important;
    }
}
</style>
<script>
// @ is an alias to /src
import emailjs from '@emailjs/browser';
import { VueRecaptcha } from 'vue-recaptcha';

export default {
    components: {
        VueRecaptcha
    },
    data() {
        return {
            privacy: false,
            showOv: false,
            tipo_richiesta: [
                { value: 'Informazioni', text: 'Informazioni' },
                { value: 'Cerco', text: 'Cerco' },
                { value: 'Consulenza', text: 'Consulenza in sede' },
                { value: 'Vendo', text: 'Vendo' },
                { value: 'Affitto', text: 'Affitto' },
                { value: 'Valutazione', text: 'Valutazione' },
            ]
        }
    },
    name: 'ContattiView',
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
        this.scrollUp();
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        scrollUp() {
            let top = this.$el.querySelector("section");
            window.scrollTo(0, top);
        },
        onSubmit() {
            this.showOv = true
            emailjs.sendForm('phome_001', 'template_d6ym6mi', this.$refs.form, 'zC27Irk4pbYekVwl8')
                .then((result) => {
                    console.log('Email Inviata con Successo!', result.text);
                    this.$toast.success("Richiesta inviata con successo", { timeout: 5000 });
                }, (error) => {
                    console.log('Errore Invio Email...', error.text);
                    this.$toast.error("Errore nell'invio della richiesta", { timeout: 5000 });
                }).finally(() => this.showOv = false);
        }
    }
}
</script>
