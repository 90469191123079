<template>
    <div class="row" style="margin-top:30px !important">
        <div class="col-4 "><span style="font-size: 14px; text-align: left;" class="m-2">Immobili: {{ tot }}</span> </div>
        <div class="col-4 p-0 justify-content-center pagination">
            <a title="Pagina Precedente" @click="$parent.prev()"><i class="fas fa-2x fa-chevron-left"></i></a>
            <a title="Pagina Successiva" @click="$parent.next()"><i class="fas fa-2x fa-chevron-right"></i></a>
        </div>
        <div class="col-4 text-right"> <span style="font-size: 14px; text-align: left;" class="m-2"> {{ 'Pagina: ' + currentPage + '/' + pagine }} </span></div>
        <div></div>
    </div>
</template>
<script>
export default {
    name: 'PaginationComponent',
    props: {
        tot: Number,
        pagine: Number,
        currentPage: Number
    }
}
</script>
<style scoped>
.pagination span {
    font-size: 14px;
}

.pagination a {
    cursor: pointer;
    color: black;
    padding: 8px 16px;
    text-decoration: none;
    border: 1px solid #ddd;
}

.pagination a.active {
    background-color: #f5a300;
    color: white;
    border: 1px solid #011a02;
}

.pagination a:hover:not(.active) {
    background-color: #ddd;
}

.pagination a:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.pagination a:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
</style>