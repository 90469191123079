import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyCd_qDHi8CNJtlB_OoNm0YWv2li6BPU3oA",
    authDomain: "puntohomeimmobiliare-16191.firebaseapp.com",
    projectId: "puntohomeimmobiliare-16191",
    storageBucket: "puntohomeimmobiliare-16191.appspot.com",
    messagingSenderId: "285162724953",
    appId: "1:285162724953:web:b6b6268ec709efc249db48"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

import 'firebase/firestore'
export const db = getFirestore();
