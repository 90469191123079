import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue } from 'bootstrap-vue'
// Added Component
import Toast from "vue-toastification";
import VueConfirmDialog from 'vue-confirm-dialog';
import vSelect from 'vue-select';
import GoTop from '@inotom/vue-go-top'
import VuePictureSwipe from 'vue-picture-swipe';
Vue.component('vue-picture-swipe', VuePictureSwipe);
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-select/dist/vue-select.css';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import Vuelidate from 'vuelidate'
import Meta from 'vue-meta'
import VueSplide from '@cycraft/vue-splide';
import VueSocialChat from 'vue-social-chat'

Vue.use(VueSplide);
Vue.use(VueConfirmDialog)
Vue.use(BootstrapVue)
Vue.use(Toast)
Vue.use(GoTop)
Vue.use(Vuelidate)
Vue.use(Meta)
Vue.use(VueSocialChat)
Vue.component('v-select', vSelect)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)

Vue.config.productionTip = false

Vue.filter('toCurrency', function (value) {
  if (typeof value !== "number") {
      return value;
  }
  var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'EUR'
  });
  return formatter.format(value);
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
