<template>
    <b-modal @shown="clickFoto" id="modal-foto" title="Galleria" size="lg" hide-footer>
        <!-- <Splide :options="options">
            <Splide-slide v-for="f in foto" :key="f" class="ph-carosello">
                <img :src="f.src">
                <img src="../assets/images/phomelogo.png" class="logo-carosello2">
            </Splide-slide>
        </splide> -->
        <h3 style="color:red">Seleziona una foto da visualizzare</h3>
        <vue-picture-swipe :items="foto"></vue-picture-swipe>
    </b-modal>
</template>
<style>
#modal-foto {
    padding-right: 0 !important;
}
.pswp__img {
    object-fit: contain !important;
}
img[itemprop="thumbnail"] {
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 21%);
    margin: 2px;
    width: 90px !important;
    height: 72px !important;
}

img[itemprop="thumbnail"]:hover {
    border: 2px solid red;
}

.pswp__button--share {
    display: none !important;
}

.logo-carosello2 {
    width: 100px;
    height: 78px;
    margin-left: 44%;
    margin-top: 41%;
}

.ph-carosello {
    margin-bottom: 30px !important;
}

@media screen and (min-width: 992px) {
    .ph-carosello {
        margin-left: 15% !important;
    }
}

.modal-body {
    padding: 2rem !important;
    text-align: center;
}

.modal-header {
    border: none !important;
}

@media screen and (max-width: 576px) {
    .logo-carosello2 {
        width: 32px;
        height: 25px;
        margin-left: 48%;
        margin-top: 40%;
    }

}
</style>
<script>
/* eslint-disable */
import { Splide, SplideSlide } from '@cycraft/vue-splide';

export default {
    name: 'FotoSliderModal',
    props: ['foto'],
    components: {
        Splide,
        SplideSlide,
    },
    methods: {
        clickFoto() {  
            setTimeout(() => {document.querySelector('.gallery-thumbnail').click() }, 500);   
        }
    },
    data() {
        return {
            options: {
                type: 'fade',
                perPage: 1,
                rewind: true,
                pagination: true,
                cover: true,
                isNavigation: true,
                fixedWidth: 600,
                fixedHeight: 600,
                mediaQuery: 'max',
                breakpoints: {
                    576: {
                        perPage: 1,
                        fixedWidth: 350,
                        fixedHeight: 300,
                    },
                }
            },
        };
    }
}
</script>