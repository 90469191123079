<template>
  <div>
    <section class="home" id="home">
      <b-form @submit.prevent="onSubmit" @reset.prevent="onReset">
        <h3 class="text-center mb-3">Cerca la tua casa perfetta</h3>
        <div class="row buttons-container" style="margin:20px !important">
          <div class="col-6">
            <b-form-checkbox id="checkbox-vendita" size="lg" v-model="parametri_ricerca.vendita" name="checkbox-vendita"
              :value="true" :unchecked-value="false"> <span class="p-2" style="font-size: 14px"> Vendita</span>
            </b-form-checkbox>  
          </div>
          <div class="col-6">
            <b-form-checkbox id="checkbox-affitto" size="lg" v-model="parametri_ricerca.affitto" name="checkbox-affitto"
              :value="true" :unchecked-value="false"> <span class="p-2" style="font-size: 14px"> Affitto</span>
            </b-form-checkbox>
          </div>
          <!-- <b-form-radio-group id="btn-radio-tipo" size="lg" v-model="tipo_ricerca" :options="tipo_ricerca"  name="radios-btn-tipo"></b-form-radio-group> -->
        </div>
        <div class="inputBox mt-2">
          <!-- <b-form-select v-model="parametri_ricerca.zona" :options="zona"></b-form-select> -->
          <b-form-select v-model="parametri_ricerca.comune" :options="comune"></b-form-select>
          <b-form-select v-model="parametri_ricerca.tipologia" :options="tipologia"></b-form-select>
          <b-form-select v-model="parametri_ricerca.min_prezzo" :options="min_prezzo"></b-form-select>
          <b-form-select v-model="parametri_ricerca.max_prezzo" :options="max_prezzo"></b-form-select>
          <b-form-input type="number" v-model="parametri_ricerca.mq" placeholder="Superficie (MQ)"></b-form-input>
          <!-- <b-form-select v-model="parametri_ricerca.camere" :options="numero_camere"></b-form-select> -->
          <b-form-select v-model="parametri_ricerca.bagni" :options="numero_bagni"></b-form-select>
        </div>
        <b-button class="ricerca-submit" type="submit"><i class="fa fa-search"></i> Cerca</b-button>
      </b-form>
    </section>
  </div>
</template>

<style scoped src='@/assets/css/home.css'>

</style>

<script>
import { mapState } from 'vuex';

export default {
  name: 'SearchForm',
  computed: {
    ...mapState(['immobili_cache','filtro'])
  },
  mounted() {
    this.$store.commit("ADD_FILTRO", this.parametri_ricerca);
    // if(this.filtro != null) {
    //   this.parametri_ricerca = this.filtro
    // }
  },
  data() {
    return {
      tipo_ricerca: [
        { text: 'In Vendita', html: '<label style="font-size:1.5rem"><b>In Vendita</b></label>' },
        { text: 'In Affitto', html: '<label style="font-size:1.5rem"><b>In Affitto</b></label>' },
      ],
      parametri_ricerca: {
        vendita: false,
        affitto: false,
        zona: null,
        comune: null,
        tipologia: null,
        min_prezzo: null,
        max_prezzo: null,
        mq: null,
        camere: null,
        bagni: null
      },
      comune: [
        { value: null, text: 'Comune' },
        { value: '', text: 'Tutti i comuni' },
        { value: 'Corato', text: 'Corato' },
        { value: 'Ruvo di Puglia', text: 'Ruvo di Puglia' }
      ],
      zona: [
        { value: null, text: 'Zona' },
        { value: '', text: 'Tutte le zone' },
      ], // caricare da DB
      numero_camere: [
        { value: null, text: 'Camere da letto' },
        { value: 0, text: 'più di 1' },
        { value: 1, text: '1' },
        { value: 2, text: '2' },
        { value: 3, text: '3' },
        { value: 4, text: '4' },
        { value: 5, text: '5' },
        { value: 6, text: '6' }
      ],
      numero_bagni: [
        { value: null, text: 'Bagni' },
        { value: 0, text: 'più di 1' },
        { value: 1, text: '1' },
        { value: 2, text: '2' },
        { value: 3, text: '3' }
      ],
      tipologia: [
        { value: null, text: 'Tipologia' },
        { value: '', text: 'Tutte le tipologie' },
        { value: 'Appartamenti', text: 'Appartamenti' },
        { value: 'Locali e Box', text: 'Locali e Box' },
        { value: 'Capannoni', text: 'Capannoni' },
        { value: 'Attività Commerciali', text: 'Attività Commerciali' },
        { value: 'Ville e Terreni', text: 'Ville e Terreni' }
      ],
      min_prezzo: [
        { value: null, text: 'Prezzo minimo' },
        { value: 0, text: 'Nessun limite' },
        { value: 10000, text: '€ 10.000' },
        { value: 25000, text: '€ 25.000' },
        { value: 50000, text: '€ 50.000' },
        { value: 100000, text: '€ 100.000' },
        { value: 150000, text: '€ 150.000' },
        { value: 200000, text: '€ 200.000' },
        { value: 250000, text: '€ 250.000' },
        { value: 500000, text: '€ 500.000' },
      ],
      max_prezzo: [
        { value: null, text: 'Prezzo massimo' },
        { value: 9999999, text: 'Nessuno limite' },
        { value: 500000, text: '€ 500.000' },
        { value: 250000, text: '€ 250.000' },
        { value: 200000, text: '€ 200.000' },
        { value: 150000, text: '€ 150.000' },
        { value: 100000, text: '€ 100.000' },
        { value: 50000, text: '€ 50.000' },
        { value: 25000, text: '€ 25.000' },
      ]
    }
  },
  methods: {
    onSubmit() {
      // salvataggio parametri ricerca
      this.$store.commit("ADD_FILTRO", this.parametri_ricerca);
      // let p = this.parametri_ricerca;
      // this.$parent.immobili = this.$parent.immobili
      //   .filter(x => x.prezzo >= (p.min_prezzo == null ? 0 : p.min_prezzo) &&
      //     x.prezzo <= (p.max_prezzo == null ? 9999999 : p.max_prezzo))
      //   .filter(x => p.vendita ? (x.vendita == p.vendita) : (x.vendita != null))
      //   .filter(x => p.affitto ? (x.affitto == p.affitto) : (x.affitto != null))
      //   .filter(x => x.tipologia.includes(p.tipologia == null ? "" : p.tipologia))
      //   .filter(x => x.mq >= (p.mq == null || p.mq == "" ? 0 : parseInt(p.mq)))
      //   .filter(x => p.zona ? (x.zona.includes(p.zona)) : (x.zona != ""))
      //   .filter(x => p.comune ? (x.comune.includes(p.comune)) : (x.comune != ""))
      //   .filter(x => x.bagni >= (p.bagni == null ? 0 : p.bagni))
      //   .filter(x => x.camere >= (p.camere == null ? 0 : p.camere))
      //   .sort((a, b) => a.prezzo - b.prezzo)

        this.$router.push({ name: 'immobili', params: {parametri_ricerca: this.parametri_ricerca}})
      //   this.$parent.scrollDown();
    },
    onPress(i) {
      //console.log(i)
      this.buttons.forEach((b, index) => b.state = i === index)
    }
  }
}
</script>