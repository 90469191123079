<template>
    <div>
        <div class="upload">
            <slot><input type="file" accept="image/*"/></slot>
            <b-button variant="primary" class="custom-upload" @click="showUpload"><i class="fa fa-plus"></i> Aggiungi Immagine</b-button>
            <small style="font-style: italic;" :class="{ invalid: !valid }">
                {{ files.length }}
               File selezionati
            </small>
            <ul class="files m-3">
                <span class="p-1" v-for="file,index in files" :key="file.name">
                    <b-button variant="danger" @click="removeFile(index)">
                        <i class="fa fa-trash"></i>
                    </b-button>
                    {{ file.name }}
                </span>
            </ul>
        </div>
    </div>
</template>
<style scoped>
input[type="file"] {
    border: 1px solid yellow;
}

input[type="file"].slotted {
    border: 1px solid rebeccapurple;
}

.invalid {
    color: red;
}

/* .container {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
} */

.head {
    background: #de6159;
    color: white;
    padding: 85px 0 45px;
    font-size: 22px;
    margin-bottom: 35px;
    text-align: right;
}

.head h2 {
    font-weight: normal;
    line-height: 1;
    margin-top: -30px;
}
</style>
<script>
export default {
    name: 'FileSelector',
    props: {
        max: {
            type: Number,
            default: 20
        },
        value: Array
    },
    data() {
        return {
            files: [],
            input: null
        }
    },
    mounted() {
        // Find input
        this.input = this.$el.querySelector('input[type=file]');
        this.input.addEventListener('change', () => this.onFileSelection())
        this.input.style.display = 'none'

        // Set multiple attribute on input, if max is more than one
        if (this.max > 1) {
            this.input.setAttribute('multiple', 'multiple');
        } else {
            this.input.removeAttribute('multiple');
        }

        // Populate internal value, if external value is given,
        // attempt to populate external value by firing event if not
        if (this.value) {
            this.files = this.value
        } else {
            this.$emit('input', [])
        }
    },

    methods: {
    
        /**
         * Executed, when the user selects a (or multiple) new file(s)
         * @returns {void}
         */
        onFileSelection() {

            // add all selected files
            for (let file of this.input.files) {
                this.files.push(file)
            }

            // reset file input
            this.input.value = null;
        },

        /**
     * Removes the file with the given index
     * @param {number} index
     * @returns {void}
     */
        removeFile(index) {
            this.files.splice(index, 1)
        },

        showUpload() {
            const event = new MouseEvent('click', {
                'view': window,
                'bubbles': true,
                'cancelable': true
            });
            //console.log(event)
            this.input.dispatchEvent(event)
        }
    },

    computed: {
        valid() {
            return this.files.length <= this.max
        }
    },

    watch: {
        files(files) {
            this.$emit('input', files)
        }
    }
}
</script>