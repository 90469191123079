import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [vuexLocal.plugin],
  state: {
    user: "",
    immobili_cache: [],
    filtro: {
      vendita: false,
      affitto: false,
      zona: null,
      comune: null,
      tipologia: null,
      min_prezzo: null,
      max_prezzo: null,
      mq: null,
      camere: null,
      bagni: null
    }
  },
  getters: {
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user
    },
    ADD_FILTRO(state, filtro) {
      state.filtro = filtro
    },
    ADD_IMMOBILE(state, immobile) {
      state.immobili_cache.push(immobile)
    },
    ADDALL_IMMOBILI(state, immobili) {
      state.immobili_cache = [...immobili]
    },
  },
  actions: {
  },
  modules: {
  }
})
