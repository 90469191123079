import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import DettaglioImmobileView from '../views/DettaglioImmobileView.vue'
import ChiSiamoView from '../views/ChiSiamoView.vue'
import ServiziView from '../views/ServiziView.vue'
import ImmobiliListView from '../views/ImmobiliListView.vue'
import ContattiView from '../views/ContattiView.vue'
import AdminPanel from '../views/AdminPanel.vue'
import AreaRiservataView from '../views/AreaRiservataView.vue';
import PrivacyView from '../views/PrivacyView.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/chisiamo',
    name: 'chisiamo',
    component: ChiSiamoView
  },
  {
    path: '/servizi',
    name: 'servizi',
    component: ServiziView
  },
  {
    path: '/contatti',
    name: 'contatti',
    component: ContattiView
  },
  {
    path: '/areariservata',
    name: 'areariservata',
    component: AreaRiservataView
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminPanel
  }, 
  {
    path: '/immobili',
    name: 'immobili',
    component: ImmobiliListView,
    props: true
  },
  {
    path: '/immobile/:id',
    name: 'immobile',
    component: DettaglioImmobileView,
    props: true
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: PrivacyView
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
