<template>
    <section class="container">
        <div class="row p-1 mt-3">
            <div class="col-sm-12 col-md-12 col-lg-4 col-xl-3" style="justify-content: center; padding: 0">
                <h1 class="text-center">Privacy & Policy</h1>
                <hr class="customhr">
            </div>
        </div>
        <!-- PRIVACY E POLICY -->
        <div class="row p-1 mt-3">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xl-12 m-3" style="font-size: 18px;">
                <div class="ph-frame">
                    <p>
                        <b>I – INFORMAZIONI RELATIVE ALLA PRIVACY POLICY DEL SITO</b>
                    <ul>
                        <li>1. In questa sezione sono contenute le informazioni relative alle modalità di gestione di
                            puntohomeimmobiliare.it in riferimento alle trattamento dei dati degli utenti di
                            puntohomeimmobiliare.it.
                        </li>
                        <li>2. La presente informativa ha valore anche ai fini dell’art. 13 del D.lgs. n. 196/2003,
                            Codice in materia di protezione dei dati personali, e ai fini dell’art. 13 del Regolamento
                            UE n. 2016/679, relativo alla protezione delle persone fisiche con riguardo al trattamento
                            dei dati personali nonché alla libera circolazione di tali dati, per i soggetti che
                            interagiscono con puntohomeimmobiliare.it ed è raggiungibile all’indirizzo corrispondente
                            alla
                            pagina iniziale: puntohomeimmobiliare.it
                        </li>

                        <li>3. L’informativa è reale solo per puntohomeimmobiliare.it e non anche per altri siti web
                            eventualmente consultati dall’utente tramite link in esso contenuti.</li>
                        <li>4. Scopo del presente documento è fornire indicazioni circa le modalità, i tempi e la natura
                            delle informazioni che i titolari del trattamento devono fornire agli utenti al momento
                            della connessione alle pagine web di puntohomeimmobiliare.it, indipendentemente degli scopi
                            del
                            collegamento stesso, secondo la legislazione Italiana ed Europea.</li>
                        <li>5. L’informativa può subire modifiche a causa dell’introduzione di nuove norme al riguardo,
                            si invita, pertanto, l’utente a controllare periodicamente la presente pagina.</li>
                        <li>6. Se l’utente ha meno di sedici anni, ai sensi dell’art. 8, c. 1 regolamento UE 2016/679,
                            dovrà legittimare il suo consenso attraverso l’autorizzazione dei genitori o di chi ne fa le
                            veci.</li>
                    </ul>
                    </p>
                    <p>
                        <b>II - TRATTAMENTO DEI DATI</b>
                    <ul>
                        <li>
                            1. Titolare dei dati
                            1. Il titolare del trattamento è la persona fisica o giuridica, l’autorità pubblica, il
                            servizio o
                            altro organismo che, singolarmente o insieme ad altri, determina le finalità e i mezzi del
                            trattamento di dati personali. Si occupa anche dei profili sulla sicurezza.
                            2. Relativamente al presente sito web il titolare del trattamento è puntohomeimmobiliare.it e
                            per ogni
                            chiarimento o esercizio dei diritti dell’utente potrà contattarlo al seguente indirizzo
                            mail:
                            puntohomeimmobiliare@gmail.com
                        </li>
                        <li>
                            2. Responsabile del trattamento dei dati
                            1. Il responsabile del trattamento è la persona fisica o giuridica, l’autorità pubblica, il
                            servizio
                            o altro organismo che tratta dati personali per conto del titolare del trattamento.
                            2. Ai sensi dell’articolo 28 del Regolamento UE n. 2016/679, su nomina del titolare dei
                            dati, il
                            responsabile del trattamento dei dati del sito puntohomeimmobiliare.it è: Giuseppe Ortenzio
                        </li>
                        <li>
                            3. Luogo trattamento dati
                            1. Il trattamento dei dati generato dall’utilizzo di puntohomeimmobiliare.it avviene presso
                            puntohome immobiliare
                            in via: Pizza V.Emanuele, 16
                            2. In caso di necessità, i dati connessi al servizio newsletter possono essere trattati dal
                            responsabile del trattamento o soggetti dati esso incaricati a tal fine presso la relativa
                            sede.
                        </li>
                    </ul>
                    </p>
                    <p>
                        <b>III – COOKIES</b>
                    <ul>
                        <li>
                            1. Tipo di cookies
                            1. Il sito puntohomeimmobiliare.it utilizza cookies per rendere l’esperienza di navigazione
                            dell’utente
                            più
                            facile ed intuitiva: i cookies sono piccole stringhe di testo utilizzate per memorizzare
                            alcune
                            informazioni che possono riguardare l’utente, le sue preferenze o il dispositivo di accesso
                            ad
                            internet (computer, tablet o cellulare) e vengono usate principalmente per adeguare il
                            funzionamento
                            del sito alle aspettative dell’utente, offrendo un’esperienza di navigazione più
                            personalizzata
                            e
                            memorizzando le scelte effettuate in precedenza.
                            2. Un cookie consiste in un ridotto insieme di dati trasferiti al browser dell’utente da un
                            server
                            web e può essere letto unicamente dal server che ha effettuato il trasferimento. Non si
                            tratta
                            di
                            codice eseguibile e non trasmette virus.
                            3. I cookies non registrano alcuna informazione personale e gli eventuali dati
                            identificabili
                            non
                            verranno memorizzati. Se si desidera, è possibile impedire il salvataggio di alcuni o tutti
                            i
                            cookies. Tuttavia, in questo caso l’utilizzo del sito e dei servizi offerti potrebbe
                            risultarne
                            compromesso. Per procedere senza modificare le opzioni relative ai cookies è sufficiente
                            continuare
                            con la navigazione.
                            Di seguito le tipologie di cookies di cui il sito fa uso:
                        </li>

                        <li>
                            2. Cookies tecnici
                            1. Ci sono numerose tecnologie usate per conservare informazioni nel computer dell’utente,
                            che
                            poi
                            vengono raccolte da altri siti. Tra queste la più conosciuta ed utilizzata è HTML. Essi
                            servono
                            per
                            la navigazione e per facilitare l’accesso e la fruizione del sito da parte dell’utente. Sono
                            necessari alla trasmissione di comunicazioni su rete elettronica ovvero al fornitore per
                            erogare
                            il
                            servizio richiesto dal cliente.
                            2. Le impostazioni per gestire o disattivare i COOKIES possono variare a seconda del browser
                            internet utilizzato. Ad ogni modo, l’utente può gestire o richiedere la disattivazione
                            generale
                            o la
                            cancellazione dei cookies, modificando le impostazioni del proprio browser internet. Tale
                            disattivazione può rallentare o impedire l’accesso ad alcune parti del sito.
                            3. L’uso dei cookies tecnici consente la fruizione sicura ed efficiente del sito.
                            4. I COOKIES che vengono inseriti nel browser e ritrasmessi mediante Google Analytics o
                            tramite
                            il
                            servizio statistiche di blogger o similari sono tecnici solo se utilizzati ai fini di
                            ottimizzazione
                            del sito direttamente dal titolare del sito stesso, che potrà raccogliere informazioni in
                            forma
                            aggregata soul numero degli utenti e su come questi visitano il sito. A queste condizioni,
                            per i
                            COOKIES Analytics valgono le stesso regole, in tema di informativa e consenso, previste per
                            i
                            cookies tecnici.
                            5. Dal punto di vista della durata si possono distinguere cookies temporanei di sessione che
                            si
                            cancellano automaticamente al termine della sessione di navigazione e servono per
                            identificare
                            l’utente e quindi evitare il login ad ogni pagina visitata e quelli permanenti che restano
                            attivi
                            nel pc fino a scadenza o cancellazione da parte dell’utente.
                            6. Potranno essere installati cookies di sessione al fine di consentire l’accesso e la
                            permanenza
                            nell’area riservata del portale come utente autenticato.
                            7. Essi non vengono memorizzati in modo persistente ma esclusivamente per la durata della
                            navigazione fino alla chiusura del browser e svaniscono con la chiusura dello stesso. Il
                            loro
                            uso è
                            strettamente limitato alla trasmissione di identificativi di sessione costituiti da numeri
                            casuali
                            generati dal server necessari per consentire l’esplorazione sicura ed efficiente del sito.
                        </li>
                        <li>
                            3. Cookies di terze parti
                            1. In relazione alla provenienza si distinguono i cookies inviati al browser direttamente
                            dal
                            sito
                            che si sta visitando e quelli di terze parti inviati al computer dati altri siti e non da
                            quello
                            che
                            si sta visitando.
                            2. I cookies permanenti sono spesso cookies di terze parti.
                            3. La maggior parte dei cookies di terse parti è costituita da cookies di tracciamento usati
                            per
                            individuare il comportamento online, capire gli interessi e quindi personalizzare le
                            proposte
                            pubblicitarie per gli utenti.
                            4. Potranno essere installati cookies di terze parti analitici. Essi sono inviati da domini
                            di
                            predette terze parti esterni al sito.
                            5. I cookies analitici di terze parti sono impiegati per rilevare informazioni soul
                            comportamento
                            degli utenti su puntohomeimmobiliare.it. La rilevazione avviene in forma anonima, al fine di
                            monitorare le
                            prestazioni e migliorare l’usabilità del sito. I cookies di profilazione di terze parti sono
                            utilizzati per creare profili relativi agli utenti su puntohomeimmobiliare.it, al fine di
                            proporre
                            messaggi
                            pubblicitari in linea con le scelte manifestate degli utenti medesimi.
                            6. L’utilizzo di questi cookies è disciplinato dalle regole predisposte dalle terze parti
                            medesime.
                            Pertanto, si invitano gli utenti a prendere visione delle informative privacy e delle
                            indicazioni
                            per gestire o disabilitare i cookies pubblicate nelle relative pagine web.
                            4.Cookies di profilazione
                            1. Sono cookies di profilazione quello a creare profili all’utente e vengono utilizzati al
                            fine
                            di
                            inviare messaggi pubblicitari in linea con le preferenze manifestate dallo stesso
                            nell’ambito
                            della
                            navigazione in rete.
                            2. Quando si utilizzeranno questi tipi di COOKIES l’utente dovrà dare un consenso esplicito.
                            3. Si applicherà l’articolo 22 del Regolamento UE 2016/679 e l’articolo 122 del Codice in
                            materia di
                            protezione dei dati.
                        </li>
                    </ul>
                    </p>
                    <p>
                        <b>IV - DATI TRATTATI</b>
                    <ul>
                        <li>
                            1.Modalità trattamento dati
                            1. Come tutti i siti web anche il presente sito fa uso di log files nei quali vengono
                            conservate
                            informazioni raccolte in maniera automatizzata durante le visite degli utenti. Le
                            informazioni
                            raccolte potrebbero essere le seguenti:
                            Indirizzo internet protocol (IP);
                            – Tipo di browser e parametri del dispositivo usato per connettersi al sito;
                            – Nome dell’internet service provider (ISP);
                            – Data e orario di visita;
                            – Pagina web di provenienza del visitatore (referal) e di uscita;
                            – Eventualmente il numero di click.
                            2. Le suddette informazioni sono test rate in forma automatizzata e raccolte in forma
                            esclusivamente
                            aggregata al fine di verificare il corretto funzionamento del sito e per motivi di
                            sicurezza. Tali
                            informazioni saranno test rate in base ai legittimi interessi del titolare.
                            3. Ai fini di sicurezza (filtri anti-spam, firewall, rilevazione virus) i dati registrati
                            automaticamente possono eventualmente comprendere anche dati personali come l’indirizzo IP,
                            che
                            potrebbe essere utilizzato, conformemente alle leggi vigenti in materia, a fine di bloccare
                            tentativi di danneggiamento al sito medesimo o di recare danno ad altri utenti o attività
                            dannose o
                            costituenti reato. Tali dati non sono mai utilizzati per l’identificazione o la profilazione
                            dell’utente, ma solo ai fini di tutela del sito e dei suoi utenti, tali informazioni saranno
                            usate
                            in base ai legittimi interessi del titolare.
                            4. Qualora il sito consenta l’inserimento di commenti, oppure in caso di specifici servizi
                            richiesti
                            dall’utente, ivi compresi la possibilità di inviare il Curriculum Vitae per un eventuale
                            rapporto
                            lavorativo, il sito rileva automaticamente e registra alcuni dati identificativi
                            dell’utente,
                            compreso l’indirizzo mail. Tali dati si intendono volontariamente forniti dall’utente al
                            momento
                            della richiesta di erogazione del servizio. Inserendo un commento o altra informazione
                            l’utente
                            accetta espressamente l’informativa privacy e, in particolare, acconsente che i contenuti
                            inseriti
                            siano liberamente diffusi anche a terzi. I dati ricevuti verranno utilizzati esclusivamente
                            per
                            l’erogazione del servizio richiesto e per il solo tempo necessario per la fornitura del
                            servizio.
                            5. Le informazioni che gli utenti del sito riterranno di rendere pubbliche tramite i servizi
                            e gli
                            strumenti messi a disposizione degli stessi, sono fornite dall’utente consapevolmente e
                            volontariamente, esentando il presente sito da qualsiasi responsabilità in merito ad
                            eventuali
                            violazioni delle leggi. Spetta all’utente verificare di avere i permessi per l’immissione di
                            dati
                            personali di terzi o di contenuti tutelati dalle norme nazionali ed internazionali.
                        </li>
                        <li>
                            2.Finalità del trattamento dati
                            1. I dati raccolti dal sito durante il suo funzionamento sono utilizzati esclusivamente per
                            le
                            finalità sopra indicate e conservati per il tempo strettamente necessario a svolgere le
                            attività
                            precisate e, comunque, non oltre 2 anni.
                            2. I dati utilizzati ai fini di sicurezza (blocco tentativi di danneggiamento del sito) sono
                            conservati per il tempo strettamente necessario al raggiungimento del fine anteriormente
                            indicato.
                        </li>
                        <li>
                            3.Dati forniti dall’utente
                            1. Come sopra indicato, l’invio facoltativo, esplicito e volontario di posta elettronica
                            agli
                            indirizzi indicate su questo sito comporta la successive acquisizione dell’indirizzo del
                            mittente,
                            necessario per rispondere alle richieste, nonché degli eventuali altri dati personali
                            inseriti nella
                            missiva.
                            2. Specifiche informative di sintesi verranno progressivamente riportate o visualizzate
                            nelle pagine
                            del sito predisposte per particolari servizi a richiesta.
                        </li>
                        <li>
                            4.Supporto nella configurazione del proprio browser
                            1. L’utente può gestire i cookies anche attraverso le impostazioni del suo browser.
                            Tuttavia,
                            cancellando i cookies dal browser potrebbe rimuovere le preferenze che ha impostato per il
                            sito. Per
                            ulteriori informazioni e supporto è possible anche visitare la pagina di aiuto specifica del
                            web
                            browser che si sta utilizzando:
                            – Internet explorer
                            http://windows.microsoft.com/en-us/windows-vista/block-or-allow-cookies
                            – Firefox
                            https://support.mozilla.org/en-us/kb/enable-and-disable-cookies-website-preferences
                            – Safari
                            http://www.apple.com/legal/privacy/it/
                            – Chrome
                            https://support.google.com/accounts/answer/61416?hl=it
                            – Opera
                            http://www.opera.com/help/tutorials/security/cookies /
                        </li>
                        <li>
                            5.Plugin Social Network
                            1. Il presente sito incorpora anche plugin e/o bottoni per i social network, al fine di
                            consentire
                            una facile condivisione dei contenuti sui vostri social network preferiti. Tali plugin sono
                            programmati in modo da non impostare alcun cookie all’accesso della pagina per salvaguardare
                            la
                            privacy degli utenti. Eventualmente i cookies vengono impostati, se così previsto dai social
                            network
                            solo quando l’utente fa effettivo e volontario uso del plugin. Si tenga presente che se
                            l’utente
                            naviga essendo loggato nel social network allora ha già acconsentito all’uso dei cookies
                            veicolati
                            tramite questo sito al momento dell’iscrizione al social network.
                            2. La raccolta e l’uso delle informazioni ottenute a mezzo del plugin sono regolati dalle
                            rispettive
                            informative privacy dei social network, alle quali si prega di fare riferimento:
                            <p>
                            <ul>
                                <li>
                                    – Facebook
                                    https://www.facebook.com/help/cookies</li>
                                <li>
                                    – Twitter
                                    https://support.twitter.com/articles/20170519-uso-dei-cookie-e-d-altre-tecnologie-simili-da-parte-di-twitter
                                </li>
                                <li>
                                    – Google +
                                    http://www.google.com/polices/techonologies/cookies</li>
                                <li>
                                    – Pinterest
                                    https://about.pinterest.com/it/privacy-policy</li>
                                <li>
                                    – AddThis
                                    http://www.addthis.com/privacy/privacy-policy</li>
                                <li>
                                    – Linkedin
                                    https://www.linkedin.com/legal/cookie/policy
                                </li>
                            </ul>
                            </p>
                        </li>
                    </ul>
                    </p>
                    <p>
                        <b>V- DIRITTI DELL’UTENTE</b>
                    <ul>
                        <li>1. L’art. 13 co. 2 del Regolamento UE del 2016/679 elenca i diritti dell’utente.</li>
                        <li> 2. Il presente sito puntohomeimmobiliare.it intende, pertanto, informare l’utente
                            sull’esistenza:
                            – del diritto dell’interessato di chiedere al titolare l’accesso ai dati personali (art. 15
                            Regolamento UE), il loro aggiornamento (art. 7, co. 3, lett. a del D.lgs 196/2003), la
                            rettifica
                            (art. 16 Regolamento UE), l’integrazione (art. 7, co. 3 lett. a D.lgs 196/2003), la
                            limitazione
                            del
                            trattamento che lo riguardino (art. 18 Regolamento UE) o di opporsi, per motivi legittimi,
                            al
                            loro
                            trattamento (art. 21 Regolamento UE), oltre al diritto alla portabilità dei dati (art. 20
                            Regolamento UE);
                            – del diritto di chiedere la cancellazione (art. 17 Regolamento UE), la trasformazione in
                            forma
                            anonima o il blocco dei dati trattati in violazione di legge, compresi quelli di cui non è
                            necessaria la conservazione in relazione agli scopi per i quali i dati sono stati raccolti o
                            successivamente trattati (art. 7 co. 3, lett. b del D.lgs. 196/2003);
                            – del diritto di ottenere l’attestazione che le operazioni di aggiornamento, rettificazione,
                            integrazione dei dati, cancellazione, blocco dei dati, trasformazione, sono state portate a
                            conoscenza, anche per quanto riguarda il loro contenuto, di coloro ai quali i dati sono
                            stati
                            comunicati o diffusi, eccettuato il caso in cui tale adempimento si rivela impossibile o
                            comporta un
                            impiego di mezzi manifestamente sproporzionato rispetto al diritto tutelato (art. 7 co. 3,
                            lett.
                            c
                            del D.lgs 196/2003).</li>
                        <li>
                            3. Le richieste possono essere indirizzate al titolare del trattamento al suo indirizzo
                            email
                            summenzionato (senza formalità) oppure utilizzando il modello previsto dal Garante per la
                            protezione
                            dei dati personali.</li>
                        <li>
                            4. Qualora il trattamento sia basato sull’art. 6 paragrafo 1 lettera a – consenso espresso
                            all’utilizzo – oppure sull’art. 9 paragrafo 2 lettera a – consenso espresso all’utilizzo di
                            dati
                            genetici, biometrici, relativi alla salute, che rivelino convinzioni religiose, filosofiche
                            o
                            appartenenza sindacale, che rivelino l’origine razziale o etnica, le opinioni politiche –
                            l’utente
                            ha il diritto di revocare il consenso in qualsiasi momento senza pregiudicare la liceità del
                            trattamento basata sul consenso prestato prima della revoca.</li>
                        <li>
                            5. Parimenti, in caso di violazione della normativa, l’utente ha il diritto di proporre
                            reclamo
                            presso il Garante per la Protezione dei Dati Personali, quale autorità preposta al controllo
                            sul
                            trattamento nello Stato italiano.</li>
                        <li>
                            6. Per una disamina più approfondita dei diritti che Le competono, si vedano gli artt. 15 e
                            ss.
                            Del
                            Regolamento UE 2016/679 e l’art. 7 del D.lgs. 196/2003.</li>
                    </ul>
                    </p>
                    <p>
                        <b>VI – ADEMPIMENTI</b>
                    <ul>
                        <li>
                            1. Il titolare notifica al Garante il trattamento di dati personali cui intende procedere,
                            solo
                            se
                            il trattamento riguarda:
                            – dati genetici, biometrici o dati che indicano la posizione geografica di persone od
                            oggetti
                            mediante una rete di comunicazione elettronica;
                            – dati idonei a rivelare lo stato di salute e la vita sessuale, trattati ai fini di
                            procreazione
                            assistita, prestazione di servizi sanitari per via telematica relativi a banche di dati o
                            alla
                            fornitura di beni, indagini epidemiologiche, rilevazione di malattie mentali, infettive e
                            diffusive,
                            sieropositività, trapianto di organi e tessuti e monitoraggio della spesa sanitaria;
                            – dati idonei a rivelare la vita sessuale o la sfera psichica, trattati da associazioni,
                            enti ed
                            organismi senza scopo di lucro, anche non riconosciuti, a carattere politico, filosofico,
                            religioso
                            o sindacale;
                            – dati trattati con l’ausilio di strumenti elettronici volti a definire il profilo o la
                            personalità
                            dell’interessato o ad analizzare abitudini e scelte di consumo ovvero a monitorare
                            l’utilizzo di
                            servizi di comunicazione elettronica con esclusione dei trattamenti tecnicamente
                            indispensabili
                            per
                            fornire i servizi medesimi agli utenti;
                            – dati sensibili registrati in banche di dati a fini di selezione del personale per conto
                            terzi
                            nonché dati sensibili utilizzati per sondaggi di opinione, ricerche di mercato ed altre
                            ricerche
                            campionarie;
                            – dati registrati in apposite banche dati gestite con strumenti elettronici e relative al
                            rischio
                            sulla solvibilità economica, alla situazione patrimoniale, al corretto adempimento di
                            obbligazioni,
                            a comportamenti illeciti o fraudolenti.
                        </li>
                    </ul>
                    </p>
                    <p>
                        <b>VI – SICUREZZA DEI DATI FORNITI</b>
                    <ul>
                        <li>
                            1. Il presente sito tratta i dati degli utenti in maniera lecita e corretta, adottando le
                            opportune
                            misure di sicurezza volte ad impedire accessi non autorizzati, divulgazione, modifica o
                            distruzione
                            non autorizzata dei dati. Il trattamento viene effettuato mediante strumenti informatici e/o
                            telematici, con modalità organizzative e con logiche strettamente correlate alle finalità
                            indicate.</li>
                        <li>
                            2. Oltre al titolare, in alcuni casi, potrebbero avere accesso ai dati categorie di
                            incaricati
                            coinvolti nell’organizzazione del sito (personale amministrativo, marketing, commerciale,
                            legali,
                            amministratori di sistema) ovvero soggetti esterni come (come fornitori di servizi tecnici
                            terzi,
                            corrieri postali, hosting provider, società informatiche, agenzie di comunicazione).</li>
                    </ul>
                    </p>
                    <p>
                        <b>VIII – MODIFICHE AL PRESENTE DOCUMENTO</b>
                    <ul>
                        <li>
                            1. Il presente documento, pubblicato all’indirizzo: puntohomeimmobiliare.it/privacy
                            costituisce la privacy policy di questo sito.</li>
                        <li>
                            2. Esso può essere soggetto a modifiche o aggiornamenti. Qualora si tratti di modifiche e
                            aggiornamenti rilevanti questi saranno segnalati con apposite notifiche agli utenti.</li>
                        <li>
                            3. Le versioni precedenti del documento saranno consultabili, comunque, a questa pagina.
                        </li>
                        <li>
                            4. Il documento è stato aggiornato in data 09/03/2023 per essere conforme alle disposizioni
                            normative
                            in materia ed in particolare al Regolamento UE 2016/679.</li>
                    </ul>
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>
<style scoped>
ul {
    word-wrap: break-word;
    padding: 2px;
    list-style: none;
}


li {
    padding: 5px;
    margin-left: 10px;
}

li i {
    color: var(--red);
    padding: 5px;
}

p {
    font-size: 16px;
    text-align: justify;
}

section {
    min-height: 100vh;
    height: 100%;
    margin-top: 150px;
    margin-bottom: 100px;
    font-size: 16px !important;
}

@media screen and (max-width: 576px) {
    section {
        margin-top: 100px !important;
        margin-right: auto !important;
    }
}

.servizi-icon {
    color: var(--red);
    padding: 5px;
}
</style>
<script>
// @ is an alias to /src

export default {
    name: 'PrivacyView',
    components: {

    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
        this.scrollUp();

    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        scrollUp() {
            let top = this.$el.querySelector("section");
            window.scrollTo(0, top);
        }
    }
}
</script>
