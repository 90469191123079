<template>
  <div>
    <SearchForm />
    <PropertiesCp :immobili="immobili" :show="'true'" />
  </div>
</template>
<style>

</style>
<script>
// @ is an alias to /src
import SearchForm from '@/components/SearchForm.vue';
import PropertiesCp from '@/components/Properties.vue';
import { mapState } from 'vuex';
import { getCollection } from '@/service/dataStoreController';

export default {
  name: 'HomeView',

  components: {
    SearchForm,
    PropertiesCp
  },
  computed: {
    ...mapState(['immobili_cache', 'filtro'])
  },
  data() {
    return {
      immobili: [],
      scTimer: 0,
      scY: 0
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.loadImmobili();
    this.scrollUp();
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    loadImmobili() {
      /* Caricamento Immobili */

      //if (this.immobili_cache.length == 0) {
      //console.log('CACHE:immobili -> INSERT');
      getCollection('immobili').then((docs) => {
        const data = [];
        docs.forEach((doc) => {
          data.stato
          //console.log(doc.data())
          data.push(doc.data());
        });
        this.immobili = data
        this.immobili.sort((a, b) => this.fixData(b.data) - this.fixData(a.data))
        this.$store.commit('ADDALL_IMMOBILI', data);
      });
      //} else {
      // console.log('CACHE:immobili -> READ')
      //this.immobili = [...this.immobili_cache];
      //this.immobili.sort((a, b) => this.fixData(b.data) - this.fixData(a.data))
    },
    fixData(dataString) {
      let myDate = dataString.split("-");
      let newDate = myDate[2] + "-" + myDate[1] + "-" + myDate[0];
      return Date.parse(newDate)
    },
    scrollDown() {
      const top = this.$el.querySelector("#featured").offsetTop - 110;
      window.scrollTo(0, top);
      //this.$el.querySelector("#featured").scrollIntoView({ black: 'start', behavior: 'smooth' });
    },
    scrollUp() {
      let top = this.$el.querySelector("#home");
      window.scrollTo(0, top);
    }
  }
}
</script>
