<template>
    <section class="container">
        <div class="row p-1 mt-3">
            <div class="col-sm-12 col-md-12 col-lg-4 col-xl-3" style="justify-content: center; padding: 0">
                <h1 class="text-center">Servizi</h1>
                <hr class="customhr">
            </div>
        </div>
        <!-- SERVIZI -->
        <div class="ph-frame">
            <div class="row mt-3">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xl-12" style="font-size: 18px;">
                    <p>Nel settore immobiliare <b>Puntohome</b> vede la presenza di figure professionali
                        altamente
                        specializzate che da anni operano in questo settore e che hanno approfondito competenze
                        professionali.<br><br>
                        L'<b>agente Immobiliare</b> è un professionista che analizza e risolve le problematiche
                        connesse
                        alla
                        compravendita e alla locazione dei beni immobili, quindi offrendo assistenza tecnica,
                        fiscale e
                        legale gratuita dal momento della proposta d'acquisto fino alle stipula del contratto
                        definitivo
                        (rogito). E' una figura esperta capace di dialogare con altri professionisti in diversi
                        ambiti come
                        quello legale, finanziario fiscale e tributario, nonché sempre attento all'evolversi del
                        mercato
                        immobiliare. L'agente immobiliare negli anni ha consolidato le proprie conoscenze e continua
                        a
                        sviluppare il proprio bagaglio professionale. <br><br>Presso la nostra agenzia il Cliente
                        potrà
                        essere
                        assistito nell'ambito immobiliare con una particolare attenzione agli aspetti fiscali nei
                        vari atti
                        di possesso dei propri immobili e nel nell'assolvimento della documentazione necessaria al
                        rispetto
                        dei vari adempimenti previsti dalle norme in materia.<br><br>
                        Inoltre i nostri <b>servizi</b>:
                    </p><br>
                    <!-- ELENCO -->
                    <p class="mt-2"><i class="fas fa-2x fa-hand-holding-usd mr-4"></i>per
                        coloro che sono
                        intenzionati alla&nbsp;<b>vendita del proprio immobile:</b></p>
                    <ul>
                        <li><i class="fas fa-check-circle"></i> valutazioni dell'immobile attraverso lo studio del
                            mercato immobiliare;</li>
                        <li><i class="fas fa-check-circle"></i> promozione pubblicitaria dell'immobile tramite
                            specifiche riviste del settore, il sito
                            internet, social, cartellonistica in sede e sul posto;</li>
                        <li><i class="fas fa-check-circle"></i> verifica documenti catastali e pratiche
                            urbanistico/edilizie;</li>
                        <li><i class="fas fa-check-circle"></i> assistenza nelle trattative, della proposta di
                            acquisto
                            sino al rogito notarile di
                            compravendita.</li>
                    </ul>
                    <p class="mt-5"><i class="fas fa-2x fa-home mr-4"></i>
                        per coloro che devono&nbsp;<b>acquistare:</b></p>
                    <ul>
                        <li><i class="fas fa-check-circle"></i> consulenza personalizzata per la ricerca
                            dell'immobile;
                        </li>
                        <li><i class="fas fa-check-circle"></i> assistenza del cliente in tutti i passaggi della
                            compravendita, dal preliminare di compravendita al rogito notarile;</li>
                        <li><i class="fas fa-check-circle"></i> assistenza e gestione della pratica di mutuo presso
                            i
                            principali istituti bancari;</li>
                        <li><i class="fas fa-check-circle"></i> accertamento e verifica sullo stato degli immobili
                            risultanti dai pubblici registri.</li>
                    </ul>
                    <p class="mt-5"><i class="fas fa-2x fa-house-user mr-4"></i>
                        per coloro che sono intenzionati a&nbsp;<b>locare il loro immobile:</b></p>
                    <ul>
                        <li><i class="fas fa-check-circle"></i> consulenza personalizzata al fine di sottoscrivere
                            un
                            contratto di locazione che tenga conto
                            delle necessità delle parti 'conduttore - locatore';</li>
                        <li><i class="fas fa-check-circle"></i> gestione degli affitti: registrazione contratti;
                        </li>
                        <li><i class="fas fa-check-circle"></i> accertamenti sui potenziali inquilini e loro
                            solvibilità.</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
</template>
<style scoped>
ul {
    padding: 2px;
    list-style: none;
}


li {
    padding: 5px;
    margin-left: 10px;
}

li i {
    color: var(--red);
    padding: 5px;
}

p {
    text-align: justify;
}

section {
    min-height: 100vh;
    height: 100%;
    margin-top: 150px;
    margin-bottom: 100px;
    font-size: 16px !important;
}

@media screen and (max-width: 576px) {
    section {
        margin-top: 100px !important;
        margin-right: auto !important;
    }
}

.servizi-icon {
    color: var(--red);
    padding: 5px;
}
</style>
<script>
// @ is an alias to /src

export default {
    name: 'ServiziView',
    components: {

    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
        this.scrollUp();

    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        scrollUp() {
            let top = this.$el.querySelector("section");
            window.scrollTo(0, top);
        }
    }
}
</script>
