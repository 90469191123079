<template>
    <section id="dettaglio-section" class="container">
        <router-link class="btn btn-back" style="font-size: 16px !important" :to="{ path: '/immobili/' }">
            <i class="fa fa-arrow-left"></i> Torna agli Immobili
        </router-link>
        <!-- <span style="float:right; font-size: 14px;"> {{ 'Ultimo aggiornamento: ' + immobile.data }}</span> -->
        <b-card style="border:none; background-color: transparent">
            <div class="row p-1 m-3">
                <div class="col-sm-12 text-center">
                    <h1 class="m-3" style="text-transform: uppercase;">
                        <span class="titolo" v-if="immobile.vendita">VENDITA</span>
                        <span class="titolo" v-if="immobile.affitto">AFFITTO</span>
                        {{ ' ' + immobile.tipologia }}
                    </h1>
                </div>
                <div class="col-sm-12 text-center">
                    <hr class="customhr">
                    <div class="d-flex justify-content-center">
                        <i class="fa fa-lg fa-map-marker-alt mr-2 d-flex mt-1" style="color:red"></i>
                        <h3 class="p-1"><b>{{ immobile.comune }}</b> - {{ immobile.zona }}</h3>
                    </div>

                    <!-- <h2 class="m-3" style="font-style: italic;"> {{ immobile.descrizione }} </h2> -->
                </div>
            </div>
            <div class="row p-1">
                <div class="col-lg-8 col-sm-12 p-3">
                    <b-card>
                        <Splide :options="mainOptions">
                            <Splide-slide v-for="f in foto" :key="f" class="ph-carosello2">
                                <img :src="f">
                                <!-- <img src="../assets/images/phomelogo.png" class="logo-carosello"> -->
                                <b-button @click="showImage(foto.indexOf(f))" class="m-2 btn-back btn-back-galleria" style="border:none; font-size: 16px !important;"><i
                                        class="fa fas fa-expand"></i> Ingrandisci</b-button>
                            </Splide-slide>
                        </Splide>
                        <!-- <b-carousel id="carousel-1" :v-model="immobile.foto" :interval="4000" controls indicators
                            background="#ababab" img-width="800" img-height="600" style="text-shadow: 1px 1px 2px #333;">
                            <div v-for="f in immobile.foto" :key="f">
                                <b-carousel-slide>
                                    <template #img>
                                        <img class="d-block img-fluid w-100" width="1024" height="480" :src="f"
                                            alt="image slot">
                                    </template>
                                </b-carousel-slide>
                            </div>
                        </b-carousel> -->
                    </b-card>
                    <vue-picture-swipe :items="fotos"></vue-picture-swipe>
                </div>
                <div class="col-lg-4 col-sm-12 p-3">
                    <b-card>
                        <div class="ph-frame mb-3">
                            <h2><i class="custom-i fa fa-bars"></i> Descrizione</h2>
                            <hr class="customhr">
                            <div class="row info-immobile mb-3">
                                <div class="col-12">
                                    <p class="m-3" style="text-align: justify;">
                                        {{ immobile.descrizione }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="ph-frame mb-3">
                            <h2><i class="custom-i fa fa-list-alt"></i> Informazioni</h2>
                            <hr class="customhr">
                            <div class="row info-immobile">
                                <div class="col-lg-6 col-sm-12">
                                    <p class="p-info"><i class="fa fa-check"></i><strong> Stato:</strong> {{
                                        immobile.stato
                                    }}
                                    </p>
                                </div>
                                <div class="col-lg-6 col-sm-12">
                                    <p class="p-info"><i class="fas fa-expand"></i><strong> MQ: </strong> {{
                                        immobile.mq
                                    }} </p>
                                </div>
                                <div class="col-lg-6 col-sm-12">
                                    <p class="p-info"><i class="fas fa-door-closed"></i><strong> Vani: </strong> {{
                                        immobile.camere
                                    }} </p>
                                </div>
                                <div class="col-lg-6 col-sm-12">
                                    <p class="p-info"><i class="fa fa-bath"></i><strong> Bagni: </strong> {{
                                        immobile.bagni
                                    }}
                                    </p>
                                </div>
                                <div class="col-12">
                                    <p v-if="!isTrattativa" class="p-info"><i class="fa fa-home"></i><strong> Prezzo: </strong>
                                        {{
                                            immobile.prezzo | toCurrency
                                        }}
                                    </p>
                                    <p v-if="isTrattativa" class="p-info"><i class="fa fa-home"></i><strong> Prezzo: </strong>
                                        Trattativa in sede
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="ph-frame mb-3">
                            <h2> <i class="custom-i fa fa-info-circle"></i> Richiedi Informazioni</h2>
                            <hr class="customhr">
                            <b-overlay :show="showOv" variant="secondary" spinner-variant="" rounded="sm">
                                <div class="row">
                                    <div class="col-lg-12 col-sm-12">
                                        <b-form ref="form" @submit.prevent="onSubmit" @reset.prevent="onReset">
                                            <b-form-input class="m-2" type="text" name="from_immobile" hidden
                                                :value="immobile.nome"></b-form-input>
                                            <b-form-input class="m-2" type="text" name="from_url" hidden
                                                :value="getPage"></b-form-input>
                                            <b-form-input class="m-2" type="text" name="from_name"
                                                placeholder="Nome e cognome"></b-form-input>
                                            <b-form-input class="m-2" type="email" name="from_email"
                                                placeholder="Email"></b-form-input>
                                            <b-form-input class="m-2" type="tel" name="from_tel"
                                                placeholder="Telefono"></b-form-input>
                                            <b-form-textarea id="textarea-default" class="m-2" name="from_message"
                                                placeholder="Sono interessato all'immobile e vorrei maggiori informazioni"></b-form-textarea>
                                            <b-form-checkbox class="m-3" id="checkbox-privacy" size="lg" v-model="privacy"
                                                name="checkbox-privacy" :value="true" :unchecked-value="false"> <span
                                                    class="p-1" style="font-size: 13px">Accetto
                                                    <router-link to="/privacy">
                                                        Privacy
                                                    </router-link></span>
                                            </b-form-checkbox>
                                            <vue-recaptcha class="m-2"
                                                sitekey="6LdYLdAkAAAAAHjK_NkC6roN0VjrNAPC3-QBDVZ4"></vue-recaptcha>
                                            <b-button :disabled="!privacy" class="m-2 btn-back" type="submit"
                                                style="border:none; font-size: 16px !important;"><i
                                                    class="fa fa-paper-plane"></i> Invia</b-button>
                                        </b-form>
                                    </div>
                                    <!-- <div class="mt-4 col-lg-6 col-sm-12">
                                        <p>
                                            Se tra tutti gli immobili proposti non hai trovato quello che stavi
                                            cercando,
                                            compila
                                            il form per lasciare la tua richiesta. Descrivici il tipo di immobile a cui
                                            sei
                                            interessato, quando ci sarà un'occasione rispondente alle tue esigenze ti
                                            ricontatteremo.
                                        </p>
                                    </div> -->
                                </div>
                            </b-overlay>
                        </div>
                    </b-card>
                </div>
            </div>
        </b-card>
    </section>
</template>
<style scoped>
.ph-carosello2 {
    margin-bottom: 30px !important;
}

.card {
    border: none;
    background-color: transparent;
}

.card-body {
    padding: 0px;
}

.custom-i {
    color: var(--red);
    padding: 5px;
}

.info-immobile i {
    color: var(--red);
    padding: 5px;
}

.logo-carosello {
    width: 100px;
    height: 78px;
    margin-left: 45%;
    margin-top: 40%;
}

h2 {
    font-weight: bold;
}

section {
    min-height: 100vh;
    height: 100%;
    margin-top: 150px;
    margin-bottom: 100px;
    font-size: 16px !important;
}

@media screen and (max-width: 576px) {
    .logo-carosello {
        width: 32px;
        height: 25px;
        margin-left: 48%;
        margin-top: 40%;
    }

    section {
        min-height: 100vh;
        height: 100%;
        margin-top: 100px;
        margin-bottom: 100px;
        font-size: 16px !important;
    }
}

p {
    font-size: 16px !important;
}

.titolo {
    color: var(--red);
    background: var(--light-red);
    border-radius: 0.5rem;
    padding: 0.2rem 1.5rem;
}

.p-info {
    font-size: 14px !important;
}

.btn-back-galleria:hover {
    background-color: #ffffff79 !important;
}
</style>
<script>
// @ is an alias to /src
import { mapState } from 'vuex';
import emailjs from '@emailjs/browser';
import { VueRecaptcha } from 'vue-recaptcha';
import { Splide, SplideSlide } from '@cycraft/vue-splide';

export default {
    name: 'DettaglioImmobileView',
    props: {
        id: String
    },
    components: {
        VueRecaptcha,
        Splide,
        SplideSlide
    },
    computed: {
        ...mapState(['immobili_cache']),
        getPage() {
            return document.location.href
        },
        isTrattativa() {
            if (isNaN(this.immobile.prezzo) || this.immobile.prezzo == null) {
                return true
            } else return false
        }
    },

    data() {
        return {
            fotos: [],
            privacy: false,
            showOv: false,
            immobile: {},
            foto: null,
            mainOptions: {
                autoplay: true,
                interval: 5000,
                pauseOnHover: true,
                type: 'loop',
                perPage: 1,
                cover: true,
                gap: '1rem',
                lazyLoad: true,
                fixedWidth: 770,
                fixedHeight: 524,
                pagination: true,
                mediaQuery: 'max',
                breakpoints: {
                    576: {
                        perPage: 1,
                        fixedWidth: 350,
                        fixedHeight: 300,
                    },
                }
            }
        }
    },
    created() {
        this.foto = this.immobili_cache.filter(immobile => immobile.id == this.id)[0].foto;
        this.foto.forEach(f => {
            this.fotos.push({
                src: f,
                thumbnail: f,
                w: 900,
                h: 900,
                downloadEl: false
            })
        })
    },
    mounted() {
        this.immobile = this.immobili_cache.filter(immobile => immobile.id == this.id)[0];
        window.addEventListener('scroll', this.handleScroll);
        this.scrollUp();
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        showImage(index) {
            let images = this.$el.querySelectorAll('img[itemprop="thumbnail"]');
            images[index].click()
        },
        scrollUp() {
            let top = this.$el.querySelector("section");
            window.scrollTo(0, top);
        },
        onSubmit() {
            this.showOv = true
            emailjs.sendForm('phome_001', 'template_dsmpv3l', this.$refs.form, 'zC27Irk4pbYekVwl8')
                .then((result) => {
                    console.log('Email Inviata con Successo!', result.text);
                    this.$toast.success("Richiesta inviata con successo", { timeout: 5000 });
                }, (error) => {
                    console.log('Errore Invio Email...', error.text);
                    this.$toast.error("Errore nell'invio della richiesta", { timeout: 5000 });
                }).finally(() => this.showOv = false);
        }
    }
}
</script>
