<template>
    <div>
        <b-overlay :show="showOv" variant="secondary" spinner-variant="" rounded="sm">
            <b-card class="mb-2" title="Gestione Immobili">
                <b-table striped hover show-empty :current-page="currentPage" :items="filtered" :fields="fields"
                    responsive :per-page="perPage">
                    <!-- filtri -->
                    <template slot="top-row">
                        <td>
                            <b-input v-model="filters.tipologia" type="text" placeholder="Tipologia" />
                        </td>
                        <td>
                            <b-input v-model="filters.comune" type="text" placeholder="Comune" />
                        </td>
                        <td>
                            <b-input v-model="filters.zona" type="text" placeholder="Zona" />
                        </td>
                        <td>
                            <b-input v-model="filters.mq" type="number" placeholder="MQ" />
                        </td>
                        <td>
                            <b-input v-model="filters.camere" type="number" placeholder="Camere" />
                        </td>
                        <td>
                            <b-input v-model="filters.bagni" type="number" placeholder="Bagni" />
                        </td>
                        <td>
                            <b-input v-model="filters.prezzo" type="number" placeholder="Prezzo" />
                        </td>
                        <td>                     
                            <b-form-checkbox id="checkbox-vendita" v-model="filters.affitto" name="checkbox-vendita"
                                :value="false" :unchecked-value="''">
                            </b-form-checkbox>                       
                        </td>
                        <td>
                            <b-form-checkbox id="checkbox-affitto" v-model="filters.vendita" name="checkbox-affitto"
                                :value="false" :unchecked-value="''">
                            </b-form-checkbox>
                        </td>
                        <td>
                            <b-input v-model="filters.stato" type="text" placeholder="Stato" />
                        </td>
                        <td>
                            <!-- foto -->
                        </td>
                        <td>
                            <div class="row">
                                <div class="col-12">
                                    <b-form-datepicker id="example-datepicker"
                                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                        v-model="filtroData" class="mb-2"></b-form-datepicker>
                                </div>
                                <div class="col-6 p-1 text-right">
                                    <b-button @click="filtraData" title="Ricerca" variant="success"><i
                                            class="fa fa-search"></i>
                                    </b-button>
                                </div>
                                <div class="col-6 p-1 text-left">
                                    <b-button @click="resetFiltroData" title="Reset" variant="danger"><i
                                            class="fa fa-times"></i>
                                    </b-button>
                                </div>
                            </div>
                        </td>
                        <td></td>
                    </template>
                    <!-- fields -->
                    <template #cell(nome)="data">
                        <span>{{ data.value }} </span>
                    </template>
                    <template #cell(tipologia)="data">
                        <span>{{ data.value }} </span>
                    </template>
                    <template #cell(comune)="data">
                        <span>{{ data.value }} </span>
                    </template>
                    <template #cell(zona)="data">
                        <span>{{ data.value }} </span>
                    </template>
                    <template #cell(mq)="data">
                        <span>{{ data.value }} </span>
                    </template>
                    <template #cell(camere)="data">
                        <span>{{ data.value }} </span>
                    </template>
                    <template #cell(bagni)="data">
                        <span>{{ data.value }} </span>
                    </template>
                    <template #cell(descrizione)="data">
                        <span>{{ data.value }} </span>
                    </template>
                    <template #cell(prezzo)="data">
                        <span v-if="!isNaN(data.value)">{{ data.value | toCurrency }} </span>
                        <span v-if="isNaN(data.value)">{{ 'Trattativa in sede' }} </span>
                    </template>
                    <template #cell(vendita)="data">
                        <span>{{ data.value? 'In vendita' : '' }} </span>
                    </template>
                    <template #cell(affitto)="data">
                        <span>{{ data.value? 'In affitto' : '' }} </span>
                    </template>
                    <template #cell(stato)="data">
                        <span>{{ data.value }} </span>
                    </template>
                    <template #cell(foto)="data">
                        <b-button @click="showModal(data.value)" variant="info"><i class="fa fa-image"></i></b-button>
                    </template>
                    <template #cell(data)="data">
                        <span>{{ data.value }} </span>
                    </template>
                    <template #cell(id)="data">
                        <b-button-group>
                            <b-button @click="editImmobilile(data)" variant="warning">Modifica</b-button>
                            <b-button @click="handleClick(data.item)" variant="danger">Elimina</b-button>
                        </b-button-group>
                    </template>
                </b-table>
                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                    aria-controls="immobili-table" style="float: right"></b-pagination>
            </b-card>
            <FotoSliderModal :foto="fotoModal" style="margin-top: 300px !important" />
        </b-overlay>
    </div>
</template>
<style scoped>

</style>
<script>
/* eslint-disable */
import FotoSliderModal from '@/components/FotoSliderModal.vue';
import { deleteAllegati, deleteData, getCollection } from '@/service/dataStoreController';
import { formatDate } from '@/service/utils';

export default {
    name: 'ImmobiliTable',
    components: {
        FotoSliderModal
    },
    computed: {
        filtered() {
            const filtered = this.immobili.filter((item) => {
                return Object.keys(this.filters).every((key) =>
                    String(item[key]).includes(this.filters[key])
                );
            });
            return filtered.length > 0
                ? filtered
                : [
                    {
                        nome: "",
                        tipologia: "",
                        comune: "",
                        zona: "",
                        mq: "",
                        camere: "",
                        bagni: "",
                        prezzo: "",
                        vendita: "",
                        affitto: "",
                        stato: "",
                        foto: "",
                        data: ""
                    },
                ];
        },
        rows() {
            return this.immobili.length;
        },
    },
    data() {
        return {
            showOv: false,
            filtroData: "",
            immobili: [],
            fotoModal: [],
            fields: [
                { key: "nome", thClass: "d-none", tdClass: "d-none" },
                "tipologia",
                "comune",
                "zona",
                "mq",
                "camere",
                "bagni",
                { key: "descrizione", thClass: "d-none", tdClass: "d-none" },
                "prezzo",
                { key: "vendita", label: "In vendita" },
                { key: "affitto", label: "In affitto" },
                "stato",
                { key: "foto", label: "Foto" },
                {
                    key: "data",
                    label: "Data ultima modifica",
                },
                { key: "id", label: "Funzioni" }
            ],
            filters: {
                id: "",
                affitto: "",
                bagni: "",
                camere: "",
                comune: "",
                descrizione: "",
                mq: "",
                nome: "",
                prezzo: "",
                stato: "",
                tipologia: "",
                vendita: "",
                zona: "",
                data: ""
            },
            perPage: 10,
            currentPage: 1,
        }
    },
    mounted() {
        this.loadData();
    },
    methods: {
        showModal(foto) {
            let fotos = []
            foto.forEach(f => {
                fotos.push({
                    src: f,
                    thumbnail: f,
                    w: 820,
                    h: 720,
                    downloadEl: false
                })
            })
            this.fotoModal = fotos;
            this.$bvModal.show('modal-foto');
        },
        editImmobilile(data) {
            this.$parent.editImmobile(data.item)
        },
        loadData() {
            getCollection('immobili').then((docs) => {
                const data = [];
                docs.forEach((doc) => {
                    data.push(this.mapData(doc.id, doc.data()));
                });
                this.immobili = [...data];
            });
        },
        mapData(uuid, data) {
            const immobile = {
                id: uuid,
                affitto: data.affitto,
                bagni: data.bagni,
                camere: data.camere,
                comune: data.comune,
                descrizione: data.descrizione,
                mq: data.mq,
                nome: data.nome,
                prezzo: data.prezzo,
                stato: data.stato,
                tipologia: data.tipologia,
                vendita: data.vendita,
                zona: data.zona,
                foto: data.foto,
                data: data.data
            }
            return immobile
        },
        handleClick(data) {
            this.$confirm(
                {
                    message: 'Confermi l\'operazione?',
                    button: {
                        no: 'No',
                        yes: 'Si'
                    },
                    callback: confirm => {
                        if (confirm) {
                            this.showOv = true;
                            deleteData('immobili', data.id).then(() => {
                                console.log("Numero allegati:" + data.foto.length)
                                deleteAllegati(data.foto).then(() => {
                                    this.$toast.success("Operazione effettuata con successo", {
                                        timeout: 2000,
                                    });
                                    this.loadData();
                                })
                            }).catch(() => {
                                this.$toast.error("Errore nell'operazione", {
                                    timeout: 2000,
                                });
                            }).finally(() => {
                                this.showOv = false;
                            })
                        }
                    }
                })
        },
        fixDate(data) {
            return formatDate(data);
        },
        filtraData() {
            /* eslint-disable */
            if (this.filtroData == "" || this.filtroData == null) {
                return "";
            }
            const myDate = this.filtroData.split("-");
            const newDate = myDate[2] + "-" + myDate[1] + "-" + myDate[0];
            this.filters.data = newDate;
        },
        resetFiltroData() {
            this.filtroData = "";
            this.filters.data = "";
        }
    }
}
</script>