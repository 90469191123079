<template>
    <div id="phome-footer">
        <footer class="footer-section">
            <div class="footer-cta pt-5 pb-5">
                <div class="row">
                    <div class="footer-box col-xl-3 col-lg-3 col-md-3 col-sm-3 mb-1">
                        <div class="single-cta">
                            <i class="fas fa-map-marker-alt"></i>
                            <div class="cta-text">
                                <h4>Dove siamo</h4>
                                <div class="row">
                                    <div class=" col-12">
                                        <span class="span-zona">Pizza V.Emanuele, 16</span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <span class="span-zona">70033 - Corato, Bari</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="footer-box col-xl-3  col-lg-3 col-md-3 col-sm-3 mb-1">
                        <div class="single-cta">
                            <i class="fas fa-phone"></i>
                            <div class="cta-text">
                                <h4>Telefono</h4>
                                <span>+39 334 713 7174</span>
                            </div>
                        </div>
                    </div>
                    <div class="footer-box col-xl-3 col-lg-3 col-md-3 col-sm-3 mb-1">
                        <div class="single-cta">
                            <i class="far fa-envelope-open"></i>
                            <div class="cta-text">
                                <h4>Email</h4>
                                <span><a
                                        href="mailto:puntohomeimmobiliare@gmail.com">puntohomeimmobiliare@gmail.com</a></span>
                            </div>
                        </div>
                    </div>
                    <div class="text-right col-xl-3 col-md-3 col-sm-3 mb-1 footer-social-icon p-0">
                        <a target="_blank" href="https://www.facebook.com/Puntohomeimmobiliare"><i id="fb-icon"
                                class="fab fa-facebook-f facebook-bg"></i></a>
                        <a target="_blank" href="#"><i id="ig-icon" class="fab fa-instagram instagram"></i></a>
                    </div>
                </div>
            </div>
            <div class="copyright-area">
                <div class="row" style="padding-left: 20% !important; padding-right: 20% !important; margin:auto !important">
                    <div class="col-xl-6 col-lg-6 col-sm-12 text-center text-lg-left">
                        <div class="copyright-text">
                            <p>Copyright &copy; 2022, All Right Reserved <a href="#">PuntoHome</a></p>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-sm-12 d-none d-lg-block text-right">
                        <div class="footer-menu">
                            <ul>
                                <li>
                                    <router-link to="/">
                                        Home
                                    </router-link>
                                </li>
                                <li>
                                    <router-link to="/chisiamo">
                                        Chi Siamo
                                    </router-link>
                                </li>
                                <li>
                                    <router-link to="/servizi">
                                        Servizi
                                    </router-link>
                                </li>
                                <li>
                                    <router-link to="/contatti">
                                        Contatti
                                    </router-link>
                                </li>
                                <li>
                                    <router-link to="/privacy">
                                        Privacy & Policy
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </footer>
    </div>
</template>
<style scoped>
#fb-icon:hover {
    background-color: #fff;
    color: #3B5998;
    transform: scale(1.1);
}

.instagram:hover {
    background: unset;
    background-color: #fff;
    color: #bc318f;
    transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.instagram {
    background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
}

ul {
    margin: 0px;
    padding: 0px;
}

.footer-section {
    background: #151414;
    position: relative;
}

.footer-cta {
    border-bottom: 1px solid #373636;
    padding-left: 8% !important;
    padding-right: 8% !important;
}

.single-cta i {
    color: #ff5e14;
    font-size: 30px;
    float: left;
    margin-top: 8px;
}

.cta-text {
    padding-left: 15px;
    display: inline-block;
    margin-bottom: 5px;
}

.cta-text h4 {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 2px;
}

.cta-text span,
a {
    color: #adacac;
    font-size: 14px;
}

@media screen and (max-width: 575px) {
    .footer-box {
        padding-bottom: 10px;
    }
}

@media screen and (min-width: 576px) and (max-width: 837px) {
    .footer-box {
        padding: 0px;
    }

    .single-cta i {
        color: #ff5e14;
        font-size: 14px;
        float: left;
        margin-top: 8px;
    }

    .cta-text h4 {
        color: #fff;
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 2px;
    }

    .cta-text span,
    a {
        color: #adacac;
        font-size: 10px;
    }
}

@media screen and (min-width: 838px) and (max-width: 992px) {
    .single-cta i {
        color: #ff5e14;
        font-size: 20px;
        float: left;
        margin-top: 8px;
    }

    .cta-text h4 {
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 2px;
    }

    .cta-text span,
    a {
        color: #adacac;
        font-size: 12px;
    }
}

@media screen and (min-width: 992px) {
    .footer-cta {
        padding-left: 10% !important;
        padding-right: 10% !important;
    }
}

.footer-content {
    position: relative;
    z-index: 2;
}

.footer-pattern img {
    position: absolute;
    top: 0;
    left: 0;
    height: 330px;
    background-size: cover;
    background-position: 100% 100%;
}

.footer-logo {
    margin-bottom: 30px;
}

.footer-logo img {
    max-width: 200px;
}

.footer-text p {
    margin-bottom: 14px;
    font-size: 14px;
    color: #7e7e7e;
    line-height: 28px;
}

.footer-social-icon span {
    color: #fff;
    display: block;
    font-size: 20px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 20px;
}

.footer-social-icon a {
    color: #fff;
    font-size: 16px;
    margin-right: 15px;
}

.footer-social-icon i {
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 38px;
    border-radius: 50%;
}

.facebook-bg {
    background: #3B5998;
}

.twitter-bg {
    background: #55ACEE;
}

.google-bg {
    background: #DD4B39;
}

.footer-widget-heading h3 {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 40px;
    position: relative;
}

.footer-widget-heading h3::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -15px;
    height: 2px;
    width: 50px;
    background: #ff5e14;
}

.footer-widget ul li {
    display: inline-block;
    float: left;
    width: 50%;
    margin-bottom: 12px;
}

.footer-widget ul li a:hover {
    color: #ff5e14;
}

.footer-widget ul li a {
    color: #878787;
    text-transform: capitalize;
}

.subscribe-form {
    position: relative;
    overflow: hidden;
}

.subscribe-form input {
    width: 100%;
    padding: 14px 28px;
    background: #2E2E2E;
    border: 1px solid #2E2E2E;
    color: #fff;
}

.subscribe-form button {
    position: absolute;
    right: 0;
    background: #ff5e14;
    padding: 13px 20px;
    border: 1px solid #ff5e14;
    top: 0;
}

.subscribe-form button i {
    color: #fff;
    font-size: 22px;
    transform: rotate(-6deg);
}

.copyright-area {
    background: #202020;
    padding: 25px 0;
}

.copyright-text p {
    margin: 0;
    font-size: 14px;
    color: #878787;
}

.copyright-text p a {
    color: #ff5e14;
}

.footer-menu li {
    display: inline-block;
    margin-left: 20px;
}

.footer-menu li:hover a {
    color: #ff5e14;
}

.footer-menu li a {
    font-size: 14px;
    color: #878787;
}
</style>
<script>
export default {
    name: 'FooterPage',
}
</script>