<template>
    <div id="insert-immobile">
        <b-overlay :show="showOv" variant="secondary" spinner-variant="" rounded="sm">
            <b-card class="m-3 p-1">
                <h3 class="mb-3" style="color:grey;">Registra un nuovo immobile</h3>
                <b-form class="m-3 p-3" @submit.prevent="addImmobile" @reset.prevent="clearInputs" autocomplete="off">
                    <!-- titolo -->
                    <b-form-group id="input-group-nome" label-for="input-nome" class="mb-2">
                        <label>Titolo: </label>
                        <b-form-input id="input-nome" :state="validateState('nome')" v-model="form.nome"
                            placeholder="Titolo"></b-form-input>
                        <b-form-invalid-feedback id="input-error">Campo Obbligatorio</b-form-invalid-feedback>
                    </b-form-group>
                    <!-- descrizione -->
                    <b-form-group id="input-group-descrizione" label-for="input-descrizione" class="mb-2">
                        <label>Descrizione: </label>
                        <b-form-textarea id="input-descrizione" :state="validateState('descrizione')" rows="2" max-rows="6"
                            v-model="form.descrizione" placeholder="Descrizione"></b-form-textarea>
                        <b-form-invalid-feedback id="input-error">Campo Obbligatorio</b-form-invalid-feedback>
                    </b-form-group>
                    <!-- stato -->
                    <b-form-group id="input-stato" label-for="input-stato" class="mb-2">
                        <label>Stato: </label>
                        <v-select v-model="form.stato" :class="statoError ? 'is-invalid error-select' : ''"
                            @input="checkSelect('#input-stato')" :state="validateState('stato')" :options="stato_options"
                            placeholder="Stato">
                        </v-select>
                        <b-form-invalid-feedback id="input-error">Campo Obbligatorio</b-form-invalid-feedback>
                    </b-form-group>
                    <!-- tipologia -->
                    <b-form-group id="input-tipologia" label-for="input-tipologia" class="mb-2">
                        <label>Tipologia: </label>
                        <v-select v-model="form.tipologia" :class="tipologiaError ? 'is-invalid error-select' : ''"
                            @input="checkSelect('#input-tipologia')" :options="tipologia_options" placeholder="Tipologia"
                            required></v-select>
                        <b-form-invalid-feedback id="input-error">Campo Obbligatorio</b-form-invalid-feedback>
                    </b-form-group>
                    <!-- comune -->
                    <b-form-group id="input-group-comune" label-for="input-comune" class="mb-2">
                        <label>Comune:</label>
                        <b-form-input id="input-nome" :state="validateState('comune')" v-model="form.comune"
                            placeholder="Comune"></b-form-input>
                        <b-form-invalid-feedback id="input-error">Campo Obbligatorio</b-form-invalid-feedback>
                    </b-form-group>
                    <!-- zona -->
                    <b-form-group id="input-group-zona" label-for="input-zona" class="mb-2">
                        <label>Zona:</label>
                        <b-form-input id="input-zona" :state="validateState('zona')" v-model="form.zona"
                            placeholder="Esempio: Via ..."></b-form-input>
                        <b-form-invalid-feedback id="input-error">Campo Obbligatorio</b-form-invalid-feedback>
                    </b-form-group>
                    <!-- camere -->
                    <b-form-group id="input-camere" label-for="input-camere" class="mb-2">
                        <label>Camere:</label>
                        <v-select v-model="form.camere" @input="checkSelect('#input-camere')"
                            :class="camereError ? 'is-invalid error-select' : ''" :state="validateState('camere')"
                            :options="numbers_options" placeholder="Numero" required></v-select>
                        <b-form-invalid-feedback id="input-error">Campo Obbligatorio</b-form-invalid-feedback>
                    </b-form-group>
                    <!-- bagni -->
                    <b-form-group id="input-bagni" label-for="input-bagni" class="mb-2">
                        <label>Bagni:</label>
                        <v-select v-model="form.bagni" @input="checkSelect('#input-bagni')"
                            :class="bagniError ? 'is-invalid error-select' : ''" :options="numbers_options"
                            placeholder="Numero" required></v-select>
                        <b-form-invalid-feedback id="input-error">Campo Obbligatorio</b-form-invalid-feedback>
                    </b-form-group>
                    <!-- prezzo -->
                    <b-form-group id="input-prezzo" label-for="input-prezzo"  :state="validateState('prezzo')" class="mb-2">
                        <label>Prezzo: (lasciare vuoto per 'Trattativa in sede')</label>
                        <b-form-input id="input-prezzo" type="number" placeholder="Trattativa in sede"
                            v-model="form.prezzo"></b-form-input>
                        <!-- <b-form-invalid-feedback id="input-error">Campo Obbligatorio</b-form-invalid-feedback> -->
                    </b-form-group>
                    <!-- superficie -->
                    <b-form-group id="input-mq" label-for="input-mq" class="mb-2">
                        <label>Superficie:</label>
                        <b-form-input id="input-mq" :state="validateState('mq')" type="number" v-model="form.mq"
                            placeholder="MQ"></b-form-input>
                        <b-form-invalid-feedback id="input-error">Campo Obbligatorio</b-form-invalid-feedback>
                    </b-form-group>
                    <!-- affitto / vendita -->
                    <b-form-group id="input-vendita-affitto" label-for="input-vendita-affitto" class="mb-2">
                        <label>Obiettivo:</label>
                        <div class="row ml-3">
                            <b-form-checkbox class="m-2" id="checkbox-vendita" size="lg" v-model="form.vendita"
                                name="checkbox-vendita" :value="true" :unchecked-value="false"> In Vendita
                            </b-form-checkbox>
                            <b-form-checkbox class="m-2" id="checkbox-affitto" size="lg" v-model="form.affitto"
                                name="checkbox-affitto" :value="true" :unchecked-value="false"> In Affitto
                            </b-form-checkbox>
                        </div>
                    </b-form-group>
                    <!-- foto -->
                    <b-form-group id="input-foto" label-for="input-foto" class="mb-2">
                        <label>Foto:</label>
                        <FileSelector ref="foto_caricate" :value="foto_files" />
                    </b-form-group>
                    <div v-if="edit" style="overflow-x: auto;">
                        <label style="font-weight: bold;"> Foto già presenti: </label>
                        <ul class="d-flex" style="list-style: none;">
                            <li class="m-1" v-for="(f, index) in formimmobile.foto" :key="index"> <img class="d-block"
                                    width="250" height="150" :src="f" alt="image slot" />
                                <b-button @click="removeFoto(f, index)" class="mt-1" variant="danger"><i
                                        class="fa fa-trash" /> Elimina</b-button>
                            </li>
                        </ul>
                    </div>
                    <!-- buttons -->
                    <div class="row p-3" style="float:right">
                        <b-button v-if="!edit" class="m-1" type="submit" variant="primary"><i class="fa fa-save" />
                            Registra</b-button>
                        <b-button v-if="edit" class="m-1" type="submit" variant="warning"><i class="fa fa-edit" />
                            Modifica</b-button>
                        <b-button class="m-1" type="reset" variant="danger">
                            <i class="fa fa-undo" /> Reset
                        </b-button>
                    </div>
                </b-form>
            </b-card>
        </b-overlay>
    </div>
</template>
<style scoped>
#insert-immobile input {
    font-size: 14px !important;
}

.form-group label {
    font-weight: bold !important;
}

.was-validated .form-control:valid,
.form-control.is-valid {
    background-color: #fff !important;
    background-image: unset;
    background-clip: padding-box !important;
    border: 1px solid #ced4da !important;
    border-radius: 0.25rem !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
    color: #495057 !important;
    background-color: #fff !important;
    border-color: #80bdff !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%) !important;
}

.error-select {
    border-color: #dc3545;
    padding-right: calc(1.5em + 0.75rem) !important;
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
</style>
<script>
/* eslint-disable */
import { saveData, saveAllegato, getUrlFromRef, deleteAllegati } from '@/service/dataStoreController';
import FileSelector from '@/components/FileSelector.vue';
import { formatDate } from '@/service/utils';
import { required } from 'vuelidate/lib/validators'

export default {
    name: 'InsertImmobile',
    components: {
        FileSelector,
    },
    props: {
        edit: false,
        formimmobile: {}
    },
    watch: {
        formimmobile(newValue, oldValue) {
            this.setFormData(newValue);
        },
    },
    validations: {
        form: {
            nome: {
                required
            },
            descrizione: {
                required
            },
            tipologia: {
                required
            },
            comune: {
                required
            },
            zona: {
                required
            },
            mq: {
                required
            },
            camere: {
                required
            },
            bagni: {
                required
            },
            stato: {
                required
            },
        }
    },
    data() {
        return {
            statoError: false,
            tipologiaError: false,
            bagniError: false,
            camereError: false,
            foto_files: [],
            stato_options: ['Nuovo', 'In Trattativa', 'Venduto', 'Non Disponibile'],
            numbers_options: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            tipologia_options: [
                'Appartamenti',
                'Locali e Box',
                'Capannoni',
                'Attività Commerciali',
                'Ville e Terreni'
            ],
            showOv: false,
            form: {
                id: null,
                nome: null,
                descrizione: null,
                tipologia: null,
                comune: null,
                zona: null,
                camere: null,
                bagni: null,
                prezzo: null,
                vendita: false,
                affitto: false,
                mq: null,
                stato: null,
                data: null,
                foto: []
            },
            fotoToRemove: []
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
        this.scrollUp();

    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        removeFoto(ref, index) {
            //console.log('ref foto: ' + ref)
            this.$confirm(
                {
                    message: 'Confermi l\'operazione?',
                    button: {
                        no: 'No',
                        yes: 'Si'
                    },
                    callback: confirm => {
                        if (confirm) {
                            this.fotoToRemove.push(ref)
                            this.formimmobile.foto.splice(index, 1)
                            console.log('foto da cancellare: ' + this.fotoToRemove)
                        }
                    }
                })
        },
        checkSelect(inputName) {
            this.$el.querySelector(inputName + " .vs__dropdown-toggle").style.borderColor = "";
        },
        validateSelect(data, select, errore) {
            if (data == null) {
                errore = true;
                this.$el.querySelector(select + " .vs__dropdown-toggle").style.borderColor = "#dc3545";
            } else {
                errore = false;
                this.$el.querySelector(select + " .vs__dropdown-toggle").style.borderColor = "";
            }
        },
        validateState(name) {
            if (name != 'prezzo') {
                const { $dirty, $error } = this.$v.form[name];
                return $dirty ? !$error : null;
            }
        },
        mapData(uuid, data) {
            const immobile = {
                id: uuid,
                affitto: data.affitto,
                bagni: data.bagni,
                camere: data.camere,
                comune: data.comune,
                descrizione: data.descrizione,
                mq: data.mq,
                nome: data.nome,
                prezzo: data.prezzo,
                stato: data.stato,
                tipologia: data.tipologia,
                vendita: data.vendita,
                zona: data.zona,
                data: data.data
            }
            return immobile
        },
        setFormData: function (data) {
            this.form = this.mapData(data.id, data)
        },
        validateImmobile() {
            /* form validation */
            this.validateSelect(this.form.stato, '#input-stato', this.statoError);
            this.validateSelect(this.form.tipologia, '#input-tipologia', this.tipologiaError);
            this.validateSelect(this.form.camere, '#input-camere', this.camereError);
            this.validateSelect(this.form.bagni, '#input-bagni', this.bagniError);
            this.$v.$touch()
        },
        addImmobile: async function () {
            this.validateImmobile();
            if (this.$v.$invalid) {
                this.$toast.error("Errore nella compilazione del form", {
                    timeout: 2000,
                });
            } else {
                console.log(this.edit ? 'Modifica' : 'Inserimento')
                let id = this.form.id;
                if (!this.edit) {
                    id = 'immobile_' + new Date().getTime();
                    this.form.id = id;
                    this.form.foto = []
                } else {
                    deleteAllegati(this.fotoToRemove).then(() => {
                        console.log('foto eliminate')
                    })
                    this.form.foto = [...this.formimmobile.foto] //foto vecchie
                }
                console.log('ID: ' + id)
                /* foto */
                let fotos = this.$refs.foto_caricate.files;
                this.showOv = true;
                this.form.data = formatDate(new Date());
                for (let foto of fotos) {
                    let snapshot = await saveAllegato('foto', foto, id, "foto" + new Date().getTime())
                    this.form.foto.push(await getUrlFromRef(snapshot));
                }
                console.log('url fotos:' + this.form.foto);
                /****/
                if (this.form.prezzo == null) {
                    this.form.prezzo = 'Trattativa in sede'
                } else {
                    this.form.prezzo = parseInt(this.form.prezzo); // fix prezzo   
                }
                this.form.affitto = this.form.affitto == null ? false : this.form.affitto;
                this.form.vendita = this.form.vendita == null ? false : this.form.vendita;
                saveData('immobili', this.form, id)
                    .then(() => {
                        this.$toast.success("Operazione effettuata con successo", {
                            timeout: 2000,
                        });
                        this.clearInputs();
                    })
                    .catch(() => {
                        this.$toast.error("Errore nell'operazione", {
                            timeout: 2000,
                        });
                    }).finally(() => {
                        this.scrollUp();
                        this.showOv = false;
                    })
            }
        },
        scrollUp() {
            let top = this.$el.querySelector("#admin-panel-section");
            window.scrollTo(0, top);
        },
        clearInputs: function () {
            this.edit = false
            this.formimmobile = {} // clear prop
            this.fotoToRemove = []
            this.checkSelect("#input-stato");
            this.checkSelect("#input-tipologia");
            this.checkSelect("#input-camere");
            this.checkSelect("#input-bagni");
            this.foto_files = []
            //this.$refs.foto_caricate.input = null;
            this.$refs.foto_caricate.files = []
            Object.keys(this.form).forEach(x => this.form[x] = null);
            this.form.foto = []
            this.form.affitto = false
            this.form.vendita = false
            this.$nextTick(() => {
                this.$v.$reset();
            });
        },
        formatNames(files) {
            return files.length === 1 ? files[0].name : `${files.length} file selezionati`
        }
    }
}

</script>
