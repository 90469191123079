<template>
    <section class="featured" id="featured">
        <div v-if="this.immobili.length > 0">
            <h1 v-if="show == 'true'" class="heading"> <span>In primo piano</span> immobili </h1>
            <div class="box-container" style="justify-content:center">
                <div v-for="(immobile, index) in paginated" :key="index">
                    <Box :data="immobile" />
                </div>
            </div>
            <FotoSliderModal :foto="fotoModal" style="margin-top: 300px !important" />
            <PaginationComponent :tot="immobili.length" :pagine="parseInt(Math.ceil(immobili.length / pageSize))"
                :currentPage="current" />
        </div>
        <div v-if="this.immobili.length == 0">
            <h1 class="heading">Nessun immobile trovato</h1>
        </div>
    </section>
</template>
<style scoped src='@/assets/css/featured.css'>

</style>
<script>
import Box from '@/components/Box.vue';
import FotoSliderModal from '@/components/FotoSliderModal.vue';
import PaginationComponent from '@/components/Pagination.vue'
import { mapState } from 'vuex';

export default {
    name: 'PropertiesComponent',
    props: {
        immobili: [],
        show: String
    },
    components: {
        Box,
        FotoSliderModal,
        PaginationComponent
    },
    computed: {
        ...mapState(['immobili_cache']),
        indexStart() {
            return (this.current - 1) * this.pageSize;
        },
        indexEnd() {
            return this.indexStart + this.pageSize;
        },
        paginated() {
            return this.immobili.slice(this.indexStart, this.indexEnd);
        }
    },
    data() {
        return {
            current: 1,
            pageSize: 12,
            fotoModal: []
        }
    },
    methods: {
        prev() {
            this.$parent.scrollDown();
            if (this.current > 1) {
                this.current--;
            }
        },
        next() {
            this.$parent.scrollDown();
            if (this.current < parseInt(Math.ceil(this.immobili.length / this.pageSize))) {
                this.current++;
            }
        }
    }
}
</script>