<template>
    <section id="chisiamo-section" class="container">
        <div class="row p-1 mt-3">
            <div class="col-sm-12 col-md-12 col-lg-4 col-xl-3" style="justify-content: center; padding: 0">
                <h1 class="text-center">Chi Siamo</h1>
                <hr class="customhr">
            </div>
        </div>
        <div class="ph-frame" style="padding-top: 0px !important">
            <div class="row text-center">
                <div class="col-sm-12 col-lg-3 col-md-3 col-xl-3">
                    <img src="@/assets/images/giuseppe.jpeg"
                        style="height:215px; border-radius: 50%; border: 10px solid #1f388a; margin-top: 2.5rem;">
                </div>
                <div class="mt-5 col-sm-12 col-lg-9 col-md-9 col-xl-9 text-justify" style="font-size: 18px;">
                    <p>
                        Oggi l'agenzia <b>Puntohome di Giuseppe Ortenzio</b> è presente nella realtà del mercato
                        immobiliare
                        coratino con immobili residenziali e commerciali.
                        La nostra agenzia opera con <b>professionalità, trasparenza e correttezza</b>. Il rapporto
                        personale con i
                        nostri clienti ci distingue perché abbiamo a cuore i loro desideri e le loro esigenze e
                        cerchiamo
                        con professionalità di trovare la soluzione migliore per loro.
                        I nostri clienti che desiderano una consulenza possono sempre contare sulla nostra disponibilità
                        e
                        riservatezza.<br>
                        Puntohome immobiliare ha come <b>obiettivo</b> quello di seguire costantemente l'evolversi del
                        mercato
                        immobiliare e di accompagnare i propri clienti in modo sicuro verso l'acquisto o la vendita del
                        proprio immobile. Vi aspettiamo in sede.</p>
                    <b><i>Giuseppe Ortenzio</i></b>
                </div>
            </div>
        </div>
    </section>
</template>
<style scoped>
section {
    min-height: 100vh;
    height: 100%;
    margin-top: 150px;
    margin-bottom: 100px;
    font-size: 16px !important;
}

@media screen and (max-width: 576px) {
    section {
        margin-top: 100px !important;
        margin-right: auto !important;
    }
}
</style>
<script>
// @ is an alias to /src

export default {
    name: 'ChiSiamoView',
    components: {

    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
        this.scrollUp();

    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        scrollUp() {
            let top = this.$el.querySelector("section");
            window.scrollTo(0, top);
        }
    }
}
</script>
